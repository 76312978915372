import Http from '@/services/Http'
import BaseService from '@/services/BaseService'
import httpBuildQuery from 'http-build-query'

const urlAPI = process.env.VUE_APP_APIENDPOINT

export const ContactService = {
  getContacts (filter, page, itemsPerPage) {
    let queryString = httpBuildQuery(filter)
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/pessoa-contato?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  sendContactInconsistency (data) {
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/contato/divergencia`, data)
          .then(function (res) {
            if (BaseService.validateResponseReturn(res)) {
              resolve({ data: res.data })
            }
          })
          .catch(function (err) {
            BaseService.checkSesssionExpired(err)
            reject(err)
          })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  requestId () {
    let uri = window.location.href.split('?')
    if (uri.length === 2) {
      let vars = uri[1].split('&')
      let getVars = []
      let tmp = ''
      vars.forEach(function (v) {
        tmp = v.split('=')
        if (tmp.length === 2) {
          getVars[tmp[0]] = tmp[1]
        }
      })
    }
    return uri[1]
  },
  sendContactEmail (data) {
    let url = this.requestId()
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/process/${url}/touch`, data)
          .then(function (res) {
            if (BaseService.validateResponseReturn(res)) {
              resolve({ data: res.data })
            }
          })
          .catch(function (err) {
            BaseService.checkSesssionExpired(err)
            reject(err)
          })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  }
}

export default ContactService
