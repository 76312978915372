<template>
  <div class="table-responsable">
    <img class="rounded-circle" style="width: 80%; max-width: 60px; max-height: 70%;margin-top: 11px;" :alt="responsavel.nome" :src='getImageUrl' onerror="this.src='https://assinatura.royalcargo.com.br/temporario/apresentacao/fun/default.jpg';">
    <div class="responsible-name">
      {{ responsavel.nome }} <br/>
      <span class="responsible-position">
        {{ responsavel.cargo }}
      </span><br/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ResponsavelTimeline',
  props: {
    responsavel: {
      type: Object,
      required: true
    },
    placeholder: {
      type: String,
      default: global.instanceApp.$i18n.t('filter.Filter_by')
    },
    noOptions: {
      type: String,
      default: 'Nenhum resultado encontrado'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
  },
  data () {
    return {
      searchTerm: null,
      selectedValue: null
    }
  },
  computed: {
    getImageUrl () {
      if (this.responsavel.id_responsavel_externo) {
        let now = new Date()
        return 'https://assinatura.royalcargo.com.br/temporario/apresentacao/fun/' + this.responsavel.id_responsavel_externo + '.jpg?' + now.getTime()
      }
      return ''
    }
  }
}
</script>

<style scoped>
  .responsible-position{
    font-size: 6pt;
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
  }
  .responsible-name{
    font-size: 10pt;
    font-family: 'Montserrat', sans-serif;
    font-weight: normal;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-evenly;
    padding-left: 9px;
    padding-top: 6%;
  }
  .table-responsable{
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: flex-start;
    background-color: #464646;
    min-height: 12vh;
    padding-top: 15px;
    padding-left: 15px;
    max-width: 100%;
    border-radius: 15px 15px 0 0;
  }
  .vertical-center {
    display: flex;
    align-items: center;
  }
</style>
