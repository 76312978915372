import Http from '@/services/Http'
import BaseService from '@/services/BaseService'

const urlAPI = process.env.VUE_APP_APIENDPOINT

export const FileSystemService = {

  sendPaymentCheckFile (dataContent) {
    try {
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/process/payment/check/file`, dataContent).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({
              data: res.data
            })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  }
}

export default FileSystemService
