<template>
  <div class="process-details-content">
    <div class="container">
      <div class="row full-heigth">
        <loading :active.sync="isLoading"
                 :can-cancel="false"
                 :is-full-page="fullPage"></loading>
        <!-- INICIO Detalhes  -->
        <!-- FIM Detalhes -->
        <!-- Inicio Modal Content-->
        <div class="col-md-9 modal-content">
          <div class="close-button mb-4">
            <div class="btn btn-link buttonClose d-inline-block" @click="onClose()">
              <span><i class="text-primary rc-Ativo-36"> </i></span>
            </div>
          </div>
          <form data-vv-scope="contato">
            <div class="row">
              <div class="col-md-12 mt-4">
                <div class="assunto">
                  <label>{{this.$i18n.t('form.contact.subject')}}</label>
                  <input type="text" v-model="contato.assunto" :config="editorConfig">
                </div>
              </div>
              <div class="col-md-12 mt-4">
                  <label>{{this.$i18n.t('form.contact.message')}}</label>
                  <ckeditor name="contato.mensagem" v-model="contato.mensagem" :config="editorConfig" v-validate="{ required: true }" ></ckeditor>
                  <transition name="slide" mode="">
                    <small v-if="errors.first('contato.mensagem')" class="royalc-error-field">{{ errors.first('contato.mensagem') }}</small>
                  </transition>
              </div>
            </div>
          </form>
          <div class="mt-5">
            <div class="btn-group text-right pull-right" aria-label="Geração de Fatura">
              <button type="button" v-on:click="sendContact(id_responsavel)" class="btn btn-primary btn-fill btn-block mr-2">
                Enviar
              </button>
            </div>
          </div>
        </div>
        <!-- Término Modal Content-->
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

// Services
import ContactService from '@/services/ContactService'

// Components
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import 'vue-select/dist/vue-select.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import CKEditor from 'ckeditor4-vue'
Vue.use(CKEditor)

export default {
  props: {
    id_responsavel: {
      type: Number,
      required: true
    }
  },
  components: {
    Loading
  },
  data () {
    return {
      isLoading: false,
      fullPage: true,
      subjectList: [
        {
          id: 1,
          name: 'Data de Vencimento'
        },
        {
          id: 2,
          name: 'Divergência de Taxas'
        },
        {
          id: 3,
          name: 'Fator de conversão incorreto'
        },
        {
          id: 4,
          name: 'Valor Incorreto'
        }
      ],
      faturaTaxasList: [],
      contato: {
        assunto: null,
        mensagem: null,
        taxas: []
      },
      editorConfig: {
        // Cores disponíveis
        fontSize_sizes: '14/1.1em;16/1.2em;20/1.5em;',
        colorButton_colors: '7f683b,333,555,CCC,DDD,FFF',
        filebrowserUploadUrl: process.env.VUE_APP_APIENDPOINT + 'v2/file/upload/wysiwyg?command=QuickUpload&type=Files',
        filebrowserImageUploadUrl: process.env.VUE_APP_APIENDPOINT + 'v2/file/upload/wysiwyg?command=QuickUpload&type=Images',
        fileTools_requestHeaders: {
          'Authorization': 'Bearer ' + global.instanceApp.$Cookie.get('userAuth')
        },
        height: 250,
        format_tags: 'p;h1;h2;h3;pre',
        format_h1: { element: 'h1', styles: { 'font-size': '20px', 'line-height': '30px', 'font-weight': 'bold', 'margin-top': '20px', 'margin-bottom': '20px', 'font-family': '\'Helvetica Neue\',Helvetica,Arial,sans-serif' }, attributes: { 'class': 'title' } },
        format_p: { element: 'p', styles: { 'font-size': '16px', 'line-height': '24px', 'margin-top': '16px', 'margin-bottom': '16px', 'font-family': '\'Helvetica Neue\',Helvetica,Arial,sans-serif' } },
        extraPlugins: ['mentions', 'uploadimage', 'colorbutton', 'font'],
        stylesSet: 'my_styles:/static/js/styles.js',
        fontFamily: {
          options: [
            'Helvetica,Sans-Serif'
          ]
        },
        toolbarGroups: [
          { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
          { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
          { name: 'forms', groups: [ 'forms' ] },
          { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
          { name: 'paragraph', groups: [ 'list', 'align', 'bidi', 'paragraph' ] },
          { name: 'others', groups: [ 'others' ] },
          { name: 'about', groups: [ 'about' ] }
        ],
        removeButtons: 'Font,Maximize,Anchor,Table,About,Scayt,NewPage,Save,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Find,Replace,SelectAll,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,CopyFormatting,CreateDiv,BidiRtl,BidiLtr,Language,Flash,Smiley,SpecialChar,Iframe,About',
        mentions: [
          {
            feed: this.variableList,
            marker: '{{',
            minChars: 0,
            itemTemplate: '<li data-id="{id}">' +
              '{name}}}' +
              '</li>',
            outputTemplate: '{name}}}'
          }
        ]
      }
    }
  },
  methods: {
    onAssuntoSelect () {
      if (!this.contato.assunto || this.contato.assunto.id !== 2) {
        this.contato.taxas = []
      }
    },
    // eslint-disable-next-line camelcase
    customLabel ({ taxa, moeda, valor_total }) {
      // eslint-disable-next-line camelcase
      return `${taxa} – ${moeda} ${this.$util.formatNumber(valor_total)}`
    },
    sendContact (data) {
      let _this = this
      let sendData = {
        titulo: _this.contato.assunto,
        mensagem: _this.contato.mensagem,
        idresponsavel: data
      }
      _this.isLoading = true
      _this.$validator.validateAll('contato').then((result) => {
        if (result) {
          ContactService.sendContactEmail(sendData).then(response => {
            _this.$alertSwal.toast('Mensagem enviada com sucesso!')
            _this.isLoading = false
            _this.onClose()
          }).catch(() => {
            _this.$alertSwal.toast('Não foi possível enviar e mensagem, tente novamente!', 'error')
          }).finally(() => {
            _this.isLoading = false
          })
        } else {
          _this.isLoading = false
        }
      }).catch(() => {
        _this.isLoading = false
      })
    },
    onClose () {
      let someData = { x: 123, y: 456 }
      this.$emit('close', someData)
    }
  }
}
</script>

<style scoped>
  @media (max-width: 600px) {
    .container{
      margin-left: 10px !important;
    }
    .btn-group, .btn-group-vertical {
        margin-top: -4rem !important;
    }
    .assunto{
      margin-top: -58px;
      padding-right: 10px;
    }
  }
  @media (min-width: 600px) {
    .container{
      margin-left: 80px !important;
    }
  }
  .assunto{
    display: flex;
    flex-direction: column;
  }
  .assunto input{
    border-color: rgba(119, 119, 119, 0.336);
  }
  .modal-content {
    padding: 45px;
  }

  .close-button {
    position: absolute;
    top: 15px;
    right: 33px;
    margin-left: 160px;
  }
  .container{
    margin-left: 160px;
  }
  tr.internal {
    background-color: #fcf8e3;
  }

  .process-details-content {
    max-height: 100vh;
    overflow-y: auto;
  }

</style>
