<template>
  <div class="process-details-content">
    <div class="container">
      <div class="row full-heigth">
        <loading :active.sync="isLoading"
                 :can-cancel="false"
                 :is-full-page="fullPage"></loading>
        <!-- INICIO Detalhes  -->
        <div class="col-md-3 text-left dark-box">
          <div class="row" >
            <!--  Processo -->
            <div class="row col-12 mb-5">
              <div v-if="invoice.internal_code" class="row col-12 mb-3">
                <div class="col-12 text-muted">Ref. Royal Cargo:</div>
                <div class="col-12 ">{{ invoice.internal_code }}</div>
              </div>
              <div v-if="invoice.customer_reference" class="row col-12 mb-3">
                <div class="col-12 text-muted">Referência</div>
                <div class="col-12 ">{{ invoice.customer_reference }}</div>
              </div>
            </div>
            <!--  Fatura -->
            <div class="row col-12 mb-5">
              <div v-if="invoice.numero" class="row col-12 mb-3">
                <div class="col-12 text-muted">Número da Fatura</div>
                <div class="col-12 ">{{ invoice.numero }}</div>
              </div>

              <div v-if="invoice.numero" class="row col-12 mb-3">
                <div class="col-12 text-muted">Total</div>
                <div class="col-12 ">
                  <span>{{ invoice.currency_initials }}</span> <span>{{ $util.formatNumber(invoice.estimated_value, 2) }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- FIM Detalhes -->
        <!-- Inicio Modal Content-->
        <div class="col-md-9 modal-content">
          <div class="close-button mb-4">
            <div class="btn btn-link buttonClose d-inline-block" @click="onClose()">
              <span><i class="text-primary rc-Ativo-36"> </i></span>
            </div>
          </div>

            <form data-vv-scope="claimCheck">

              <div class="row">
                <div class="col-12">
                  <div class="md-form mb-0">
                    <div class="input-group-icon input-group-icon-right mb-0">
                      <label for="nome">{{this.$i18n.t('form.invoices.customer_pay_slip')}}</label>
                      <v-select
                        label="nome"
                        class="md-form-control"
                        :clearable="false"
                        :searchable="true"
                        :options="personTypeList"
                        v-model="pessoa"
                      >
                        <template slot="no-options">
                          {{ 'Nenhum resultado encontrado' }}
                        </template>
                        <template slot="option" slot-scope="option">
                          <div class="d-center">
                            {{ option.nome }}
                          </div>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                          <div class="selected d-center">
                            {{ option.nome }}
                          </div>
                        </template>
                      </v-select>
                    </div>
                    <div class="col-md-12 mt-3" v-if="pessoa">
                      {{this.$i18n.t('form.movementType.variableField.takerName')}}
                      <div v-if="pessoa.id == 1 && invoice.idpessoa" v-on:click="showPersonData(invoice.idpessoa)" >
                        {{invoice.cliente}} ({{(invoice.clienteCpfCnpj) ? invoice.clienteCpfCnpj : 'N/A' }}) <i class="pull-right fa fa-info-circle"></i>
                      </div>
                      <div v-else-if="pessoa.id == 2 && invoice.idNotify" v-on:click="showPersonData(invoice.idNotify)">
                        {{invoice.notify}} ({{(invoice.notifyCpfCnpj) ? invoice.notifyCpfCnpj : 'N/A' }}) <i class="pull-right fa fa-info-circle"></i>
                      </div>
                      <div v-else-if="pessoa.id == 3 && invoice.idImportador" v-on:click="showPersonData(invoice.idImportador)">
                        {{invoice.importador}} ({{(invoice.importadorCpfCnpj) ? invoice.importadorCpfCnpj : 'N/A' }}) <i class="pull-right fa fa-info-circle"></i>
                      </div>
                      <div v-else-if="pessoa.id == 4 && invoice.idExportador" v-on:click="showPersonData(invoice.idExportador)">
                        {{invoice.exportador}} ({{(invoice.exportadorCpfCnpj) ? invoice.exportadorCpfCnpj : 'N/A' }}) <i class="pull-right fa fa-info-circle"></i>
                      </div>
                      <div v-else-if="pessoa.id == 5 && invoice.idAgenteOrigem" v-on:click="showPersonData(invoice.idAgenteOrigem)">
                        {{invoice.agenteOrigem}} ({{(invoice.agenteOrigemCpfCnpj) ? invoice.agenteOrigemCpfCnpj : 'N/A' }}) <i class="pull-right fa fa-info-circle"></i>
                      </div>
                      <div v-else-if="pessoa.id == 6  && invoice.agenteDestino" v-on:click="showPersonData(invoice.idAgenteDestino)" >
                        {{invoice.agenteDestino}} ({{(invoice.agenteDestinoCpfCnpj) ? invoice.agenteDestinoCpfCnpj : 'N/A' }}) <i class="pull-right fa fa-info-circle"></i>
                      </div>
                      <div v-else-if="pessoa.id == 7 && invoice.idDespachanteAduaneiro" v-on:click="showPersonData(invoice.idDespachanteAduaneiro)">
                        {{invoice.despachanteAduaneiro}} ({{(invoice.despachanteAduaneiroCpfCnpj) ? invoice.despachanteAduaneiroCpfCnpj : 'N/A' }}) <i class="pull-right fa fa-info-circle"></i>
                      </div>
                      <!-- idOutro -->
                      <div v-else-if="pessoa.id == 8 && invoice.idOutro" v-on:click="showPersonData(invoice.idOutro)">
                        {{invoice.outro}} ({{(invoice.outroCpfCnpj) ? invoice.outroCpfCnpj : 'N/A' }}) <i class="pull-right fa fa-info-circle"></i>
                      </div>
                      <div v-else>
                        N/A
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </form>

            <div class="row mt-5">
              <div class="col-12 d-flex justify-content-around" aria-label="Gerar recibo">
                    <button type="button" v-on:click="generateFile(invoice)" class="btn btn-primary">
                      Gerar Recibo
                    </button>
              </div>
            </div>
        </div>
        <!-- Término Modal Content-->
      </div>
    </div>
  </div>
</template>

<script>
// Components
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

// Services
import PersonTypeService from '@/services/PersonTypeService'
import PersonService from '@/services/PersonService'
import InvoiceService from '@/services/InvoiceService'

import PersonInfoView from '@/views/Invoice/Invoice/PersonInfoView'

export default {
  name: 'GeneratePaySlipView',
  props: {
    invoice: {
      type: Object,
      required: true
    }
  },
  components: {
    Loading,
    vSelect
  },
  data () {
    return {
      isLoading: false,
      fullPage: true,
      uploadEndpoint: process.env.VUE_APP_APIENDPOINT + 'v2/process/payment/check/file',
      message: '',
      personTypeList: [],
      pessoa: null
    }
  },
  methods: {
    mounted () {
      this.message = ''
    },
    generateFile (invoice) {
      let me = this
      me.isLoading = true

      let fileName = 'RECIBO - ' + (invoice.internal_code).replaceAll('/', '.') + '.pdf'
      InvoiceService.getReceiptFile(invoice.id_invoice, me.pessoa.id).then(res => {
        // Download file
        const url = global.window.URL.createObjectURL(new Blob([res.data]))
        const link = global.document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        global.document.body.appendChild(link)
        link.click()

        me.isLoading = false
        me.onClose()
      }).catch(() => {
        me.onClose()
        me.isLoading = false
      })
    },
    onClose () {
      let someData = { x: 123, y: 456 }
      this.$emit('close', someData)
    },
    showPersonData (idPerson) {
      let _this = this
      _this.isLoading = true

      PersonService.getPerson(idPerson).then(res => {
        _this.$modal.show(
          PersonInfoView,
          { isSuperAdmin: _this.isSuperAdmin,
            person: res.data.data },
          { draggable: false, width: '78%', height: 'auto', adaptive: true },
          { 'before-close': event => {} }
        )
        _this.isLoading = false
      })
    },
    personTypesFetch () {
      PersonTypeService.getPersonTypes({}, 1, 100).then(res => {
        this.pessoa = res.data.data[0]
        this.personTypeList = res.data.data
      })
    }
  },
  mounted () {
    this.personTypesFetch()
  },
  computed: {
  }
}
</script>

<style scoped>
  .modal-content {
    padding: 45px;
  }

  .close-button {
    position: absolute;
    top: 15px;
    right: 33px;
  }

  tr.internal {
    background-color: #fcf8e3;
  }

</style>
