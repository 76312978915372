<template>
  <div class="process-details-content">
    <div class="container">
      <div class="row full-heigth">
        <loading :active.sync="isLoading"
                 :can-cancel="false"
                 :is-full-page="fullPage"></loading>
        <!-- INICIO Detalhes  -->
        <div class="col-md-3 text-left dark-box">
          <div class="row" >
            <!--  Processo -->
            <div class="row col-12 mb-5">
              <div v-if="invoice.internal_code" class="row col-12 mb-3">
                <div class="col-12 text-muted">Ref. Royal Cargo:</div>
                <div class="col-12 ">{{ invoice.internal_code }}</div>
              </div>
              <div v-if="invoice.customer_reference" class="row col-12 mb-3">
                <div class="col-12 text-muted">Referência</div>
                <div class="col-12 ">{{ invoice.customer_reference }}</div>
              </div>
            </div>
            <!--  Fatura -->
            <div class="row col-12 mb-5">
              <div v-if="invoice.numero" class="row col-12 mb-3">
                <div class="col-12 text-muted">Número da Fatura</div>
                <div class="col-12 ">{{ invoice.numero }}</div>
              </div>
              <div v-if="invoice.numero" class="row col-12 mb-3">
                <div class="col-12 text-muted">Total</div>
                <div class="col-12 ">
                  <span>{{ invoice.currency_initials }}</span> <span>{{ $util.formatNumber(invoice.estimated_value, 2) }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- FIM Detalhes -->
        <!-- Inicio Modal Content-->
        <div class="col-md-9 modal-content">
          <div class="close-button mb-4">
            <div class="btn btn-link buttonClose d-inline-block" @click="onClose()">
              <span><i class="text-primary rc-Ativo-36"> </i></span>
            </div>
          </div>
          <form data-vv-scope="contato">
            <div class="row">
              <div class="col-md-12 mt-4">
                <label>{{this.$i18n.t('form.contact.subject')}}</label>
                <v-select
                  label="name"
                  @input="onAssuntoSelect()"
                  name="contato.assunto"
                  class="md-form-control"
                  :clearable="false"
                  :searchable="true"
                  :options="subjectList"
                  v-model="contato.assunto"
                  v-validate="{ required: true }"
                >
                  <template slot="no-options">
                    {{ 'Nenhum resultado encontrado' }}
                  </template>
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.name }}
                    </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.name }}
                    </div>
                  </template>
                </v-select>
                <transition name="slide" mode="">
                  <small v-if="errors.first('contato.assunto')" class="royalc-error-field">{{ errors.first('contato.assunto') }}</small>
                </transition>
              </div>
              <div class="col-md-12 mt-4" v-if="contato.assunto && contato.assunto.id  === 2">
                <label>{{this.$i18n.t('form.contact.taxes')}}</label>
                <multiselect
                  v-model="contato.taxas"
                  :multiple="true"
                  :max="1000"
                  track-by="taxa"
                  label="taxa"
                  name="contato.taxas"
                  :allow-empty="true"
                  placeholder="Informe uma ou mais taxas"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :custom-label="customLabel"
                  :options="invoice.taxas"
                  :show-labels="false"
                  v-validate="{ required: true }"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    <strong>{{ option.taxa }}</strong> - <strong>{{ option.moeda }}</strong> <strong>{{ $util.formatNumber(option.valor_total) }}</strong>
                  </template>
                  <template slot="option" slot-scope="{ option }">
                    <strong>{{ option.taxa }}</strong> - <strong>{{ option.moeda }}</strong> <strong>{{ $util.formatNumber(option.valor_total) }}</strong>
                  </template>
                </multiselect>
                <transition name="slide" mode="">
                  <small v-if="errors.first('contato.taxas')" class="royalc-error-field">{{ errors.first('contato.taxas') }}</small>
                </transition>
              </div>
              <div class="col-md-12 mt-4">
                  <label>{{this.$i18n.t('form.contact.message')}}</label>
                  <ckeditor name="contato.mensagem" v-model="contato.mensagem" :config="editorConfig" v-validate="{ required: true }" ></ckeditor>
                  <transition name="slide" mode="">
                    <small v-if="errors.first('contato.mensagem')" class="royalc-error-field">{{ errors.first('contato.mensagem') }}</small>
                  </transition>
              </div>
            </div>
          </form>
          <div class="mt-5">
            <div class="btn-group text-right pull-right" aria-label="Geração de Fatura">
              <button type="button" v-on:click="sendContact(invoice)" class="btn btn-primary btn-fill btn-block mr-2">
                Enviar
              </button>
            </div>
          </div>
        </div>
        <!-- Término Modal Content-->
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

// Services
import ContactService from '@/services/ContactService'

// Components
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import CKEditor from 'ckeditor4-vue'
Vue.use(CKEditor)

export default {
  name: 'InconsistencyView',
  props: {
    invoice: {
      type: Object,
      required: true
    }
  },
  components: {
    Loading,
    vSelect,
    Multiselect
  },
  data () {
    return {
      isLoading: false,
      fullPage: true,
      subjectList: [
        {
          id: 1,
          name: 'Data de Vencimento'
        },
        {
          id: 2,
          name: 'Divergência de Taxas'
        },
        {
          id: 3,
          name: 'Fator de conversão incorreto'
        },
        {
          id: 4,
          name: 'Valor Incorreto'
        }
      ],
      faturaTaxasList: [],
      contato: {
        assunto: null,
        mensagem: null,
        taxas: []
      },
      editorConfig: {
        // Cores disponíveis
        fontSize_sizes: '14/1.1em;16/1.2em;20/1.5em;',
        colorButton_colors: '7f683b,333,555,CCC,DDD,FFF',
        filebrowserUploadUrl: process.env.VUE_APP_APIENDPOINT + 'v2/file/upload/wysiwyg?command=QuickUpload&type=Files',
        filebrowserImageUploadUrl: process.env.VUE_APP_APIENDPOINT + 'v2/file/upload/wysiwyg?command=QuickUpload&type=Images',
        fileTools_requestHeaders: {
          'Authorization': 'Bearer ' + global.instanceApp.$Cookie.get('_userAuth_')
        },
        height: 250,
        format_tags: 'p;h1;h2;h3;pre',
        format_h1: { element: 'h1', styles: { 'font-size': '20px', 'line-height': '30px', 'font-weight': 'bold', 'margin-top': '20px', 'margin-bottom': '20px', 'font-family': '\'Helvetica Neue\',Helvetica,Arial,sans-serif' }, attributes: { 'class': 'title' } },
        format_p: { element: 'p', styles: { 'font-size': '16px', 'line-height': '24px', 'margin-top': '16px', 'margin-bottom': '16px', 'font-family': '\'Helvetica Neue\',Helvetica,Arial,sans-serif' } },
        extraPlugins: ['mentions', 'uploadimage', 'colorbutton', 'font'],
        stylesSet: 'my_styles:/static/js/styles.js',
        fontFamily: {
          options: [
            'Helvetica,Sans-Serif'
          ]
        },
        toolbarGroups: [
          { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
          { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
          { name: 'forms', groups: [ 'forms' ] },
          { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
          { name: 'paragraph', groups: [ 'list', 'align', 'bidi', 'paragraph' ] },
          { name: 'others', groups: [ 'others' ] },
          { name: 'about', groups: [ 'about' ] }
        ],
        removeButtons: 'Font,Maximize,Anchor,Table,About,Scayt,NewPage,Save,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Find,Replace,SelectAll,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,CopyFormatting,CreateDiv,BidiRtl,BidiLtr,Language,Flash,Smiley,SpecialChar,Iframe,About',
        mentions: [
          {
            feed: this.variableList,
            marker: '{{',
            minChars: 0,
            itemTemplate: '<li data-id="{id}">' +
              '{name}}}' +
              '</li>',
            outputTemplate: '{name}}}'
          }
        ]
      }
    }
  },
  methods: {
    onAssuntoSelect () {
      if (!this.contato.assunto || this.contato.assunto.id !== 2) {
        this.contato.taxas = []
      }
    },
    // eslint-disable-next-line camelcase
    customLabel ({ taxa, moeda, valor_total }) {
      // eslint-disable-next-line camelcase
      return `${taxa} – ${moeda} ${this.$util.formatNumber(valor_total)}`
    },
    sendContact (invoice) {
      let _this = this

      let sendData = {
        assunto: _this.contato.assunto.id,
        mensagem: _this.contato.mensagem,
        idlogisticaFatura: invoice.id_invoice,
        taxas: _this.contato.taxas
      }

      _this.isLoading = true
      _this.$validator.validateAll('contato').then((result) => {
        if (result) {
          ContactService.sendContactInconsistency(sendData).then(response => {
            _this.$alertSwal.toast('Mensagem enviada com sucesso!')
            _this.isLoading = false
            _this.onClose()
          }).catch(() => {
            _this.$alertSwal.toast('Não foi possível enviar e mensagem, tente novamente!', 'error')
          }).finally(() => {
            _this.isLoading = false
          })
        } else {
          _this.isLoading = false
        }
      }).catch(() => {
        _this.isLoading = false
      })
    },
    onClose () {
      let someData = { x: 123, y: 456 }
      this.$emit('close', someData)
    }
  }
}
</script>

<style scoped>
  .modal-content {
    padding: 45px;
  }

  .close-button {
    position: absolute;
    top: 15px;
    right: 33px;
  }

  tr.internal {
    background-color: #fcf8e3;
  }

  .process-details-content {
    max-height: 100vh;
    overflow-y: auto;
  }

</style>
