<template>
  <div  class="cd-timeline-block">
    <!-- Corpo - Timeline -->
    <div class="cd-timeline-content">
      <h5 class="mb-4 h5class">{{ translate(item.title) }}</h5>
      <span class="cd-date">{{ $util.formatDate(item.date) }}</span>
    </div>
    <div class="media"><i class="material-icons-outlined">check</i></div>
  </div>
</template>

<script>
export default {
  name: 'TimelineItem',
  props: {
    item: {
      required: true,
      default: 10
    }
  },
  data () {
    return {
      show: false
    }
  },
  methods: {
    translate (value) {
      return global.instanceApp.$i18n.t(value)
    },
    showContent () {
      this.show = true
    },
    hideContent () {
      this.show = false
    },
    formatDateTime (value, locale, emptyValue) {
      emptyValue = emptyValue || null

      if (!value) {
        return emptyValue
      }

      locale = locale || 'pt-BR'

      let options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      }

      if (!value) {
        return null
      }

      let date = new Date(value)

      if (locale === 'pt-BR') {
        options.timeZone = 'America/Sao_Paulo'

        return date.toLocaleString('pt-BR', options)
      } else {
        options.timeZone = 'America/New_York'

        return date.toLocaleString('en-US', options)
      }
    }
  }
}
</script>

<style scoped>
  .h5class{
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: flex-end;
  }
  .timeline-position .cd-timeline-block.start,.timeline-position .cd-timeline-block.awaiting-boarding, .timeline-position .cd-timeline-block.awaiting{
    padding: 0;
    border-radius: 10px;
    justify-content: space-evenly;
    align-content: flex-start;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 18px;
    padding-right: 15px;
  }
  .close-button {
    position: absolute;
    top: -5px;
    right: -3px;
  }
  .cd-timeline-content{
    width: 35%;
  }
  .cd-timeline-point .cd-timeline-content {
    margin-top: 15px;
  }
  .timeline-position .cd-timeline-content{
    background-color: rgba(255, 255, 255, 0);
    margin: 0;
    display: flex;
    flex-direction: column;
    color: #E0DCD2;
  }

  .cd-timeline-content h5,.cd-timeline-content span, .media i{
    display: flex;
    white-space: nowrap;
    color: #E0DCD2 !important;
    padding-top: 4px !important;
    height: 3px !important;
  }
  .timeline-position .media{
    display:flex;
    justify-content: flex-end;
    padding-bottom: 50px;
  }
  .timeline-position .cd-timeline-content::before{
    display:none;
  }
  .cd-timeline.center-orientation .cd-timeline-content .cd-date {
    white-space: normal;
    font-size: 0.78em;
  }
  .cd-date{
    font-family: 'Pluto Sans';
    font-style: normal;
    font-weight: 370;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: flex-end;
    text-transform: uppercase;
  }
  .boarding{
    display: flex;
    border-radius: 0 12px;
    border: 2px #ffffff00 solid;
    background-color: transparent !important;
    position:relative;
    display:inline-block;
  }
  .boarding::after,
  .boarding::before {
    content:"";
    position:absolute;
    z-index:1;
  }

  .boarding::before {
    border-radius: 0 12px;
    border: 2px #ffffff00 solid;
    border-bottom-color: #0F9D57;
    height: 70%;
    width:40%;
  }
  .boarding h5{
    color: #363636 !important;
  }
  .boarding span{
    color: #777F82 !important;
  }
</style>
