<template>
  <div class="process-details-content">
    <div class="container">
      <div class="row full-heigth">
        <loading :active.sync="isLoading"
                 :can-cancel="false"
                 :is-full-page="fullPage"></loading>
        <!-- INICIO Detalhes  -->
        <div class="col-md-3 text-left dark-box">
          <div class="row" >
            <!--  Processo -->
            <div class="row col-12 mb-5">
              <div v-if="invoice.internal_code" class="row col-12 mb-3">
                <div class="col-12 text-muted">Ref. Royal Cargo:</div>
                <div class="col-12 ">{{ invoice.internal_code }}</div>
              </div>
              <div v-if="invoice.customer_reference" class="row col-12 mb-3">
                <div class="col-12 text-muted">Referência</div>
                <div class="col-12 ">{{ invoice.customer_reference }}</div>
              </div>
            </div>
            <!--  Fatura -->
            <div class="row col-12 mb-5">
              <div v-if="invoice.numero" class="row col-12 mb-3">
                <div class="col-12 text-muted">Número da Fatura</div>
                <div class="col-12 ">{{ invoice.numero }}</div>
              </div>

              <div v-if="invoice.numero" class="row col-12 mb-3">
                <div class="col-12 text-muted">Total</div>
                <div class="col-12 ">
                  <span>{{ invoice.currency_initials }}</span> <span>{{ $util.formatNumber(invoice.estimated_value, 2) }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- FIM Detalhes -->
        <!-- Inicio Modal Content-->
        <div class="col-md-9 modal-content">
          <div class="close-button mb-4">
            <div class="btn btn-link buttonClose d-inline-block" @click="onClose()">
              <span><i class="text-primary rc-Ativo-36"> </i></span>
            </div>
          </div>

            <form data-vv-scope="paymentCheck">

              <div class="row">
                <!--  Attach -->
                <div class="col-md-12 mt-4">
                  <label>Informe aqui o comprovante de pagamento de sua fatura</label>
                  <custom-file-upload
                    v-model="paymentCheck.files"
                    name="attach"
                    rootClassName="md-form mb-0"
                    inputClassName="md-form-control"
                    v-validate="{ required: false }"
                    :max-files="1"
                    :error="errors.first('paymentCheck.attach')">
                  </custom-file-upload>
                </div>
              </div>
            </form>
            <div class="row mt-5">
              <div class="col-8">
                  <h5 v-if="showMessage">{{ message }}</h5>
              </div>
              <div class="col-4">
                  <div class="btn-group text-right pull-right" aria-label="Upload de comprovante">
                    <button type="button" v-on:click="sendFile(invoice)" :disabled="sendDisabled" class="btn btn-primary">
                      Enviar
                    </button>
                </div>
              </div>
            </div>
        </div>
        <!-- Término Modal Content-->
      </div>
    </div>
  </div>
</template>

<script>
// Components
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// Services
import FileSystemService from '@/services/FileSystemService'

// Form
import CustomFileUpload from '@/components/Forms/CustomFileUpload.vue'

export default {
  name: 'UploadPaymentVoucherView',
  props: {
    invoice: {
      type: Object,
      required: true
    }
  },
  components: {
    Loading,
    CustomFileUpload
  },
  data () {
    return {
      isLoading: false,
      fullPage: true,
      uploadEndpoint: process.env.VUE_APP_APIENDPOINT + 'v2/process/payment/check/file',
      paymentCheck: {
        files: []
      },
      message: ''
    }
  },
  methods: {
    mounted () {
      this.message = ''
    },
    sendFile (invoice) {
      let me = this
      let formData = {
        processId: invoice.id_process,
        files: this.paymentCheck.files
      }
      me.isLoading = true

      FileSystemService.sendPaymentCheckFile(formData)
        .then(dataJson => {
          this.message = dataJson.data.message
        })
        .then(function (res) {
          setTimeout(() => {
            me.isLoading = false
            me.isPageLoading = false
            me.onClose()
          }, 2000)
        }).catch(() => {
        })
    },
    onClose () {
      let someData = { x: 123, y: 456 }
      this.$emit('close', someData)
    }
  },
  computed: {
    sendDisabled () {
      return this.paymentCheck.files.length === 0
    },
    showMessage () {
      return this.message !== ''
    }
  }
}
</script>

<style scoped>
  .modal-content {
    padding: 45px;
  }

  .close-button {
    position: absolute;
    top: 15px;
    right: 33px;
  }

  tr.internal {
    background-color: #fcf8e3;
  }

</style>
