<template>
  <div class="process-details-content">
    <div class="container">
      <div class="row full-heigth">
        <loading :active.sync="isLoading"
                 :can-cancel="false"
                 :is-full-page="fullPage"></loading>
        <!-- INICIO Detalhes  -->
        <div class="col-md-3 text-left dark-box">
          <div class="row" >
            <div class="row col-12 mb-5">
              <div v-if="invoice.internal_code" class="row col-12 mb-3">
                <div class="col-12 text-muted">Ref. Royal Cargo:</div>
                <div class="col-12 ">{{ invoice.internal_code }}</div>
              </div>
              <div v-if="invoice.customer_reference" class="row col-12 mb-3">
                <div class="col-12 text-muted">Referência</div>
                <div class="col-12 ">{{ invoice.customer_reference }}</div>
              </div>
            </div>

            <div class="row col-12 mb-5">
              <div class="row col-12 mb-3" v-if="conversoes.length > 0">
                <div class="col-12 text-muted">Conversão:</div>
                <div class="col-12" v-for="(conversao, index) in conversoes" v-bind:key="index">
                  {{ conversao.moeda_origem.sigla }}: {{ $util.formatNumber((conversao.moeda_origem.fator != 1.0) ? conversao.moeda_origem.fator : null, 4, ',', '.', '-') }}
                </div>
              </div>
              <div class="row col-12 mb-3" v-if="total">
                <div class="col-12 text-muted">{{this.$i18n.t('form.invoices.amount_real')}}</div>
                <div class="col-12">
                    {{ total.moeda }} {{ $util.formatNumber(total.valor, 2)}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- FIM Detalhes -->
        <!-- Inicio Modal Content-->
        <div class="col-md-9 modal-content">
          <div class="close-button mb-4">
            <div class="btn btn-link buttonClose d-inline-block" @click="onClose()">
              <span><i class="text-primary rc-Ativo-36"> </i></span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              {{this.$i18n.t('form.invoices.customer_invoice')}}
              <v-select
                label="nome"
                class="md-form-control"
                :clearable="false"
                :searchable="true"
                :options="personTypeList"
                v-model="pessoa"
              >
                <template slot="no-options">
                  {{ 'Nenhum resultado encontrado' }}
                </template>
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    {{ option.nome }}
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.nome }}
                  </div>
                </template>
              </v-select>
            </div>
            <div class="col-md-12 mt-3" v-if="pessoa">
              {{this.$i18n.t('form.movementType.variableField.takerName')}}
              <div v-if="pessoa.id == 1 && invoice.idpessoa" v-on:click="showPersonData(invoice.idpessoa)" >
                {{invoice.cliente}} ({{(invoice.clienteCpfCnpj) ? invoice.clienteCpfCnpj : 'N/A' }}) <i class="pull-right fa fa-info-circle"></i>
              </div>
              <div v-else-if="pessoa.id == 2 && invoice.idNotify" v-on:click="showPersonData(invoice.idNotify)">
                {{invoice.notify}} ({{(invoice.notifyCpfCnpj) ? invoice.notifyCpfCnpj : 'N/A' }}) <i class="pull-right fa fa-info-circle"></i>
              </div>
              <div v-else-if="pessoa.id == 3 && invoice.idImportador" v-on:click="showPersonData(invoice.idImportador)">
                {{invoice.importador}} ({{(invoice.importadorCpfCnpj) ? invoice.importadorCpfCnpj : 'N/A' }}) <i class="pull-right fa fa-info-circle"></i>
              </div>
              <div v-else-if="pessoa.id == 4 && invoice.idExportador" v-on:click="showPersonData(invoice.idExportador)">
                {{invoice.exportador}} ({{(invoice.exportadorCpfCnpj) ? invoice.exportadorCpfCnpj : 'N/A' }}) <i class="pull-right fa fa-info-circle"></i>
              </div>
              <div v-else-if="pessoa.id == 5 && invoice.idAgenteOrigem" v-on:click="showPersonData(invoice.idAgenteOrigem)">
                {{invoice.agenteOrigem}} ({{(invoice.agenteOrigemCpfCnpj) ? invoice.agenteOrigemCpfCnpj : 'N/A' }}) <i class="pull-right fa fa-info-circle"></i>
              </div>
              <div v-else-if="pessoa.id == 6  && invoice.agenteDestino" v-on:click="showPersonData(invoice.idAgenteDestino)" >
                {{invoice.agenteDestino}} ({{(invoice.agenteDestinoCpfCnpj) ? invoice.agenteDestinoCpfCnpj : 'N/A' }}) <i class="pull-right fa fa-info-circle"></i>
              </div>
              <div v-else-if="pessoa.id == 7 && invoice.idDespachanteAduaneiro" v-on:click="showPersonData(invoice.idDespachanteAduaneiro)">
                {{invoice.despachanteAduaneiro}} ({{(invoice.despachanteAduaneiroCpfCnpj) ? invoice.despachanteAduaneiroCpfCnpj : 'N/A' }}) <i class="pull-right fa fa-info-circle"></i>
              </div>
              <!-- idOutro -->
              <div v-else-if="pessoa.id == 8 && invoice.idOutro" v-on:click="showPersonData(invoice.idOutro)">
                {{invoice.outro}} ({{(invoice.outroCpfCnpj) ? invoice.outroCpfCnpj : 'N/A' }}) <i class="pull-right fa fa-info-circle"></i>
              </div>
              <div v-else>
                N/A
              </div>
            </div>

          </div>
          <div class="mt-5">
            <div class="btn-group text-right pull-right" aria-label="Geração de Fatura">
              <button type="button" v-on:click="gerarFatura(invoice)" class="btn btn-primary btn-fill btn-block mr-2">
                Confirmar e Imprimir
              </button>
            </div>
          </div>
        </div>
        <!-- Término Modal Content-->
      </div>
    </div>
  </div>
</template>

<script>
// Services
import InvoiceService from '@/services/InvoiceService'
import PersonTypeService from '@/services/PersonTypeService'
import PersonService from '@/services/PersonService'
// Components
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import PersonInfoView from '@/views/Invoice/Invoice/PersonInfoView'

export default {
  name: 'GenerateView',
  props: {
    invoice: {
      type: Object,
      required: true
    }
  },
  components: {
    Loading,
    vSelect
  },
  data () {
    return {
      isLoading: false,
      fullPage: true,
      personTypeList: [],
      conversoes: [],
      total: null,
      pessoa: null
    }
  },
  methods: {
    showPersonData (idPerson) {
      let _this = this
      _this.isLoading = true

      PersonService.getPerson(idPerson).then(res => {
        _this.$modal.show(
          PersonInfoView,
          { isSuperAdmin: _this.isSuperAdmin,
            person: res.data.data },
          { draggable: false, width: '78%', height: 'auto', adaptive: true },
          { 'before-close': event => {} }
        )
        _this.isLoading = false
      })
    },
    personTypesFetch () {
      PersonTypeService.getPersonTypes({}, 1, 100).then(res => {
        this.pessoa = res.data.data[0]
        this.personTypeList = res.data.data
      })
    },
    atualizarFatura (invoice) {
      let _this = this
      _this.isLoading = true
      InvoiceService.postUpdate(invoice.id_invoice).then(result => {
        _this.conversoes = result.data.currencies
        _this.total = result.data.total
        _this.isLoading = false
      }).catch(() => {
        _this.isLoading = false
      })
    },
    gerarFatura (invoice) {
      let _this = this
      _this.isLoading = true

      let fileName = 'NUMERARIO - ' + (invoice.internal_code).replaceAll('/', '.') + '.pdf'
      InvoiceService.postGenerate(invoice.id_invoice, _this.pessoa.id).then(res => {
        // Download file
        const url = global.window.URL.createObjectURL(new Blob([res.data]))
        const link = global.document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        global.document.body.appendChild(link)
        link.click()

        _this.isLoading = false
        _this.onClose()
      }).catch(() => {
        _this.onClose()
        _this.isLoading = false
      })
    },
    onClose () {
      let someData = { x: 123, y: 456 }
      this.$emit('close', someData)
    },
    formatDateTime (value, locale, emptyValue) {
      emptyValue = emptyValue || null

      if (!value) {
        return emptyValue
      }

      locale = locale || 'pt-BR'

      let options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      }

      if (!value) {
        return null
      }

      let date = new Date(value)

      if (locale === 'pt-BR') {
        options.timeZone = 'America/Sao_Paulo'

        return date.toLocaleString('pt-BR', options)
      } else {
        options.timeZone = 'America/New_York'

        return date.toLocaleString('en-US', options)
      }
    }
  },
  mounted () {
    this.atualizarFatura(this.invoice)
    this.personTypesFetch()
  }
}
</script>

<style scoped>
  .modal-content {
    padding: 45px;
  }

  .close-button {
    position: absolute;
    top: 15px;
    right: 33px;
  }

  tr.internal {
    background-color: #fcf8e3;
  }

</style>
