<template>
  <div class="container-full">
    <loading :active.sync="isLoading"
                  :can-cancel="false"
                  :is-full-page="fullPage">
    </loading>
    <div class="container-timeline">
      <div class="container-left">
        <div class="header-timeline-mobile">
          <div class="header-options">
            <div class="item-header-timeline">Ref. Royal Cargo:</div>
            <div v-if="answerable.referencia" class="item-process-header-timeline">{{answerable.referencia}}</div>
            <div v-else class="item-process-header-timeline"> --------- </div>
          </div>
          <div class="header-options">
            <div class="item-header-timeline">Referência:</div>
            <div v-if="answerable.referencia_cliente" class="item-process-header-timeline">{{answerable.referencia_cliente}}</div>
            <div v-else class="item-process-header-timeline"> --------- </div>
          </div>
          <div class="header-options">
            <div class="item-header-timeline">Número BL:</div>
            <div v-if="answerable.numero_bl" class="item-process-header-timeline">{{answerable.numero_bl}}</div>
            <div v-else class="item-process-header-timeline"> ---------- </div>
          </div>
          <div class="header-options">
            <div class="item-header-timeline">Cliente:</div>
            <div v-if="answerable.cliente"  class="item-process-header-timeline"> {{answerable.cliente}}</div>
            <div v-else class="item-process-header-timeline"> --------- </div>
          </div>
          <div class="header-options">
            <div class="item-header-timeline">Total de Containers:</div>
            <div v-if="answerable.total_containers" class="item-process-header-timeline"> {{answerable.total_containers}} </div>
            <div v-else class="item-process-header-timeline"> --------- </div>
          </div>
        </div>
        <div class="button-back">
          <a href="javascript:history.back()"><i style="color: #f1f1f1;" class="ft-chevron-left"></i></a>
          <div class="process">Processos</div>
        </div>
        <div class="ori-dest">
          <div v-if="answerable.origem && answerable.destino" class="container-origin">{{answerable.origem}}  →  {{answerable.destino}}</div>
          <div v-if="answerable.referencia && answerable.referencia_cliente" class="container-ref">{{answerable.referencia}}  /  {{answerable.referencia_cliente}}</div>
        </div>
        <horizontal-scroll>
          <div class="container-scroll">
            <div class="timeline">
              <div class="timeline-position">
                <timeline-item  :item="processTimeline[processTimeline.length-1]" class="start">
                </timeline-item>
              </div>
              <div class="timeline-position">
                <timeline-item v-if="processTimeline[processTimeline.length-4]" :item="processTimeline[processTimeline.length-3]" class="start">
                </timeline-item>
                <timeline-item v-if="processTimeline[processTimeline.length-2] && !processTimeline[processTimeline.length-4]" :item="processTimeline[processTimeline.length-2]" class="start boarding">
                </timeline-item>
                <div v-if="!processTimeline[processTimeline.length-2] && !processTimeline[processTimeline.length-4]" :item="processTimeline[2]" class="awaiting cd-timeline-block">
                  <div class="awaiting-msg">
                  <div class="circle">
                    <div class="inner-circle">
                    </div>
                  </div>
                  <h5>Embarque</h5>
                  </div>
                </div>
              </div>
              <div class="timeline-position">
                <timeline-item v-if="processTimeline[processTimeline.length-4] && processTimeline[processTimeline.length-5]" :item="processTimeline[processTimeline.length-4]" class="start media-start">
                </timeline-item>
                <timeline-item v-else-if="processTimeline[processTimeline.length-4]" :item="processTimeline[processTimeline.length-4]" class="start boarding">
                </timeline-item>
                <div v-else :item="processTimeline[processTimeline.length-6]" class="awaiting cd-timeline-block">
                  <div class="awaiting-msg">
                  <div class="circle">
                    <div class="inner-circle">
                    </div>
                  </div>
                  <h5>Desembarque</h5>
                  </div>
                </div>
              </div>
              <div class="timeline-position">
                <timeline-item v-if="processTimeline[processTimeline.length-5]" :item="processTimeline[processTimeline.length-5]" class="start">
                </timeline-item>
                <div v-else :item="processTimeline[processTimeline.length-6]" class="awaiting cd-timeline-block">
                  <div class="awaiting-msg">
                  <div class="circle">
                    <div class="inner-circle">
                    </div>
                  </div>
                  <h5>Pagamento</h5>
                  </div>
                </div>
              </div>
              <div class="timeline-position media-start">
                <timeline-item v-if="processTimeline[processTimeline.length-6]" :item="processTimeline[processTimeline.length-6]" class="start">
                </timeline-item>
                <div v-else :item="processTimeline[processTimeline.length-6]" class="awaiting cd-timeline-block">
                  <div class="awaiting-msg">
                  <div class="circle">
                    <div class="inner-circle">
                    </div>
                  </div>
                  <h5>Finalizado</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </horizontal-scroll>
        <div class="table-follow-up">
          <ul class="nav line-tabs borderless flex-center mb-5">
            <li class="nav-item">
              <a class="nav-link" v-bind:class="{ active: showFollowUps }" v-on:click="displayFollowUpsAf()" data-toggle="tab" >Acompanhamentos</a>
            </li>
            <li class="nav-item" >
              <a class="nav-link" v-bind:class="{ active: showInvoices }"  v-on:click="displayInvoices()" data-toggle="tab" >Faturas</a>
            </li>
            <li class="nav-item" >
              <a class="nav-link" v-bind:class="{ active: showAnswerables }"  v-on:click="displayAnswerables()" data-toggle="tab" >Responsáveis</a>
            </li>
          </ul>
          <div class="table-responsive-size" v-if="showFollowUps">
            <div>
              <div class="mobile-table">
                <table class="table align-middle">
                  <thead>
                    <tr>
                      <th>Data</th>
                      <th>Descrição</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(followUp, index) in processFollowUps" :key="index" :class="{internal: followUp.internal}">
                      <td class="followUp">{{ formatDateTime(followUp.date) }}</td>
                      <td class="text-left" v-html="followUp.description"></td>
                    </tr>
                    <tr v-if="processFollowUps.length <= 0">
                      <td colspan="2">Nenhum acompanhamento disponível.</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        <div v-if="processFollowUps.length > 1" @click="handleClickResponsable()" class="show-more-size">Ver Todos</div>
        </div>
        <horizontal-scroll>
          <div v-if="showInvoices">
            <div class="table-responsive table">
              <table class="table align-middle" v-for="invoice in processInvoices" v-bind:key="invoice.id" >
                <thead v-if="processInvoices.length > 0">
                  <tr class="table-invoices">
                    <div v-if="invoice.tipo==2"><th>Tipo: Título</th></div>
                    <th>Número: {{invoice.numero}}</th>
                    <th>Vencimento: {{(showPayDate(invoice)) ? $util.formatDate(invoice.pay_date): '-' }}</th>
                    <th> <span>Status:</span> <span v-bind:style="formatStatus(getStatus(invoice))[0]">{{formatStatus(getStatus(invoice), '-')[1]}}</span></th>
                    <th>Valor do título: {{$util.formatNumber(invoice.estimated_value)}}</th>
                    <th></th>
                  </tr>
                </thead>
                <invoice-info :invoice="invoice"
                  :begin-opened="beginOpenedInvoice(idInvoice, invoice)"
                  @invoiceGenerated="reloadInvoices()" @reloadAttachments="reloadAttachments"/>
                <tbody v-if="processInvoices.length <= 0">
                  <tr>
                    <td colspan="2">Nenhuma fatura disponível.</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </horizontal-scroll>
        <div v-if="showAnswerables">
            <responsavel-box  v-for="responsavel in processAnswerable" v-bind:key="responsavel.id"  :responsavel="responsavel"/>
        </div>
        <div class="responsible-attachments-mobile">
          <div class="responsible-attachments">
            <div v-if="processFiles.length > 1" class="process-files-content-mobile">
              <div class="table-responsive-mobile">
                <h5 class="anexos">ANEXOS</h5>
                <hr class="hr-left-mobile">
                <table class="table align-middle">
                  <div class="height-left-mobile">
                    <thead>
                    </thead>
                      <ul class="process-files-mobile" v-for="file in processFiles" :key="file.id_file">
                        <div class="process-file-align">
                          <li class="process-files-item"><i class="ft-file"></i></li>
                          <div class="file-date">
                            <li class="process-files-item"> {{ file.file_group_name }}</li>
                            <li class="process-files-item-date">{{ $util.formatDate(file.date) }} </li>
                          </div>
                        </div>
                        <li class="process-files-item"><button class="btn btn-link no-padding"
                          v-on:click="fileDownload(file.id_file, file.name)"> <i style="color: #000 !important;"
                          class="ft-download"></i></button></li>
                      </ul>
                      <tr v-if="processFiles.length <= 0">
                        <td colspan="3">Nenhum arquivo disponível.</td>
                      </tr>
                    </div>
                  </table>
                </div>
                <div v-if="processFiles.length >= 2" @click="handleClickMobile()" class="show-more-mobile">Ver Todos</div>
              </div>
            <hr  v-if="processFiles.length > 1" class="hr-left-mobile">
            <div>
              <div v-if="responsable" class="height-responsable">
                <responsavel-timeline v-bind:key="responsable.id" :responsavel="responsable"/>
                <div class="show-more-responsable" type="button" v-on:click="inconsistencyMobile(responsable.id_responsavel)">ENVIAR E-MAIL</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-right">
        <div class="header-timeline">
          <div class="header-options">
            <div class="item-header-timeline">Ref. Royal Cargo:</div>
            <div v-if="answerable.referencia" class="item-process-header-timeline">{{answerable.referencia}}</div>
            <div v-else class="item-process-header-timeline"> --------- </div>
          </div>
          <div class="header-options">
            <div class="item-header-timeline">Referência:</div>
            <div v-if="answerable.referencia_cliente" class="item-process-header-timeline">{{answerable.referencia_cliente}}</div>
            <div v-else class="item-process-header-timeline"> --------- </div>
          </div>
          <div class="header-options">
            <div class="item-header-timeline">Número BL:</div>
            <div v-if="answerable.numero_bl" class="item-process-header-timeline">{{answerable.numero_bl}}</div>
            <div v-else class="item-process-header-timeline"> ---------- </div>
          </div>
          <div class="header-options">
            <div class="item-header-timeline">Cliente:</div>
            <div v-if="answerable.cliente"  class="item-process-header-timeline"> {{answerable.cliente}}</div>
            <div v-else class="item-process-header-timeline"> --------- </div>
          </div>
          <div class="header-options">
            <div class="item-header-timeline">Total de Containers:</div>
            <div v-if="answerable.total_containers" class="item-process-header-timeline"> {{answerable.total_containers}} </div>
            <div v-else class="item-process-header-timeline"> --------- </div>
          </div>
        </div>
        <hr class="hr-left">
        <h5 class="anexos">ANEXOS</h5>
        <div class="responsible-attachments">
          <div class="process-files-content">
            <div class="table-responsive ">
              <table class="table align-middle">
                <div class="height-left">
                    <ul class="process-files" v-for="file in processFiles" :key="file.id_file">
                      <div class="process-file-align">
                        <li class="process-files-item"><i class="ft-file"></i></li>
                        <div class="file-date">
                          <li class="process-files-item"> {{ file.file_group_name }}</li>
                          <li class="process-files-item-date">{{ $util.formatDate(file.date) }} </li>
                        </div>
                      </div>
                      <li class="process-files-item"><button class="btn btn-link no-padding"
                        v-on:click="fileDownload(file.id_file, file.name)"> <i style="color: #E0DCD2 !important;"
                        class="ft-download"></i></button></li>
                    </ul>
                    <tr v-if="processFiles.length <= 0">
                      <td colspan="3">Nenhum arquivo disponível.</td>
                    </tr>
                  </div>
                </table>
              </div>
              <div v-if="processFiles.length > 2" @click="handleClick()" class="show-more">Ver Todos</div>
            </div>
          <hr class="hr-left">
          <div>
            <div v-if="responsable" class="height-responsable">
              <responsavel-timeline v-bind:key="responsable.id" :responsavel="responsable"/>
              <div class="show-more-responsable" type="button" v-on:click="inconsistency(responsable.id_responsavel)">ENVIAR E-MAIL</div>
            </div>
          </div>
          <hr class="hr-left">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import TimelineItem from '@/components/Timeline/TimelineItem.vue'
import ProcessService from '@/services/Process/ProcessService'
import ResponsavelBox from '@/views/Kpi/Process/ResponsavelBox'
import KpiService from '@/services/KPI/KpiService'
import InvoiceInfo from '@/views/Kpi/Process/InvoiceInfo'
import Loading from 'vue-loading-overlay'
import SendEmail from '@/views/Invoice/Generate/sendEmail.vue'
import 'vue-loading-overlay/dist/vue-loading.css'
import ResponsavelTimeline from './ResponsavelTimeline.vue'
import HorizontalScroll from 'vue-horizontal-scroll'
import 'vue-horizontal-scroll/dist/vue-horizontal-scroll.css'

export default {
  name: 'ProcessDetails',
  components: {
    TimelineItem,
    InvoiceInfo,
    Loading,
    ResponsavelBox,
    HorizontalScroll,
    ResponsavelTimeline
  },
  data () {
    return {
      processTimeline: [],
      processFollowUps: [],
      processDetail: false,
      processFiles: [],
      responsable: {},
      idInvoice: null,
      processAnswerable: [],
      answerable: [],
      processInvoices: [],
      process: [],
      isLoading: false,
      fullPage: true,
      showFollowUps: false,
      showAnswerables: false,
      showInvoices: false,
      showAttachments: true
    }
  },
  methods: {
    showPayDate (row) {
      let showPayDate = true
      // Exportação
      if (row.operacao === 1 && row.dataEmbarque === null) {
        showPayDate = false
      } else if (row.operacao === 2 && row.dataDesembarque === null) {
        showPayDate = false
      }

      return showPayDate
    },
    handleClickResponsable () {
      let heightResponsable = document.querySelector('.table-responsive-size')
      let showMoreResponsable = document.querySelector('.show-more-size')
      if (heightResponsable.style.height === '100%') {
        heightResponsable.style.height = '30vh'
        showMoreResponsable.innerHTML = 'Ver Todos'
        return
      }
      heightResponsable.setAttribute('style', 'height: 100%')
      showMoreResponsable.innerHTML = 'Mostrar Menos'
    },
    handleClick () {
      let heightLeft = document.querySelector('.height-left')
      let showMore = document.querySelector('.show-more')
      if (heightLeft.style.height === '100%') {
        heightLeft.style.height = '18vh'
        showMore.innerHTML = 'Ver Todos'
        return
      }
      heightLeft.setAttribute('style', 'height: 100%')
      showMore.innerHTML = 'Ver Menos'
    },
    handleClickMobile () {
      let heightLeft = document.querySelector('.height-left-mobile')
      let showMore = document.querySelector('.show-more-mobile')
      if (heightLeft.style.height === '100%') {
        heightLeft.style.height = '18vh'
        showMore.innerHTML = 'Ver Todos'
        return
      }
      heightLeft.setAttribute('style', 'height: 100%')
      showMore.innerHTML = 'Ver Menos'
    },
    beginOpenedInvoice (idInvoice, invoice) {
      if (idInvoice) {
        if (idInvoice === invoice.id_invoice) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    },
    requestId () {
      let uri = window.location.href.split('?')
      if (uri.length === 2) {
        let vars = uri[1].split('&')
        let getVars = []
        let tmp = ''
        vars.forEach(function (v) {
          tmp = v.split('=')
          if (tmp.length === 2) {
            getVars[tmp[0]] = tmp[1]
          }
        })
      }
      return uri[1]
    },
    reloadInvoices () {
      let _this = this
      let url = this.requestId()
      _this.isLoading = true
      ProcessService.getProcessInvoices(url).then(res => {
        _this.processInvoices = res.data.data
      }).finally(() => {
        _this.isLoading = false
      })
    },
    clearAttachments () {
      this.processFiles = []
    },
    reloadAttachments () {
      this.clearAttachments()
      this.displayAttachments()
    },
    getResponsable () {
      let _this = this
      let url = this.requestId()
      if (_this.processFollowUps.length <= 0) {
        _this.isLoading = true
        ProcessService.getProcessResponsable(url).then(res => {
          _this.responsable = res.data.data
        }).finally(() => {
          _this.isLoading = false
        })
      }
      _this.showFollowUps = true
      _this.showInvoices = false
      _this.showAnswerables = false
    },
    displayFollowUps () {
      let _this = this
      let url = this.requestId()
      if (_this.processFollowUps.length <= 0) {
        _this.isLoading = true
        ProcessService.getProcessFollowUps(url).then(res => {
          _this.processFollowUps = res.data.data
        }).finally(() => {
          _this.isLoading = false
        })
      }
      _this.showFollowUps = true
      _this.showInvoices = false
      _this.showAnswerables = false
    },
    displayFollowUpsAf () {
      let _this = this
      let url = this.requestId()
      if (_this.processFollowUps.length <= 0) {
        _this.isLoading = true
        ProcessService.getProcessFollowUps(url).then(res => {
          _this.processFollowUps = res.data.data
        }).finally(() => {
          _this.isLoading = false
        })
      }
      document.querySelector('.show-more-size').style.display = 'flex'
      _this.showFollowUps = true
      _this.showInvoices = false
      _this.showAnswerables = false
    },
    displayTimeline () {
      let _this = this
      let url = this.requestId()
      if (_this.processTimeline.length <= 0) {
        _this.isLoading = true
        ProcessService.getProcessTimeline(url).then(res => {
          _this.processTimeline = res.data.data
        }).finally(() => {
          _this.isLoading = false
        })
      }
      _this.answerables()
      _this.showAttachments = true
      _this.showAnswerables = false
      _this.showFollowUps = true
    },
    displayProcess () {
      let _this = this
      let url = this.requestId()
      if (_this.process.length <= 0) {
        _this.isLoading = true
        KpiService.getInsightsTimeRelease(url).then(res => {
          _this.process = res.data.data
        }).finally(() => {
          _this.isLoading = false
        })
      }
    },
    displayInvoices () {
      let _this = this
      let url = this.requestId()
      if (_this.processInvoices.length <= 0) {
        _this.isLoading = true
        ProcessService.getProcessInvoices(url).then(res => {
          _this.processInvoices = res.data.data
        }).finally(() => {
          _this.isLoading = false
        })
      }
      document.querySelector('.show-more-size').style.display = 'none'
      _this.showFollowUps = false
      _this.showInvoices = true
      _this.showAnswerables = false
    },
    displayAttachments () {
      let _this = this
      let url = this.requestId()
      if (_this.processFiles.length <= 0) {
        _this.isLoading = true
        ProcessService.getProcessFiles(url).then(res => {
          _this.processFiles = res.data.data
        }).finally(() => {
          _this.isLoading = false
        })
      }
    },
    displayAnswerables () {
      let _this = this
      let url = this.requestId()
      if (_this.processAnswerable.length <= 0) {
        _this.isLoading = true
        ProcessService.getProcessAnswerable(url).then(res => {
          _this.processAnswerable = res.data.data
        }).finally(() => {
          _this.isLoading = false
        })
      }
      document.querySelector('.show-more-size').style.display = 'none'
      _this.showFollowUps = false
      _this.showInvoices = false
      _this.showAnswerables = true
    },
    answerables () {
      let _this = this
      let url = this.requestId()
      if (_this.answerable.length <= 0) {
        _this.isLoading = true
        ProcessService.getProcessParams(url).then(res => {
          _this.answerable = res.data.data
        }).finally(() => {
          _this.isLoading = false
        })
      }
      _this.showAnswerables = false
    },
    formatDateTime (value, locale, emptyValue) {
      emptyValue = emptyValue || null

      if (!value) {
        return emptyValue
      }

      locale = locale || 'pt-BR'

      let options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      }

      if (!value) {
        return null
      }

      let date = new Date(value)

      if (locale === 'pt-BR') {
        options.timeZone = 'America/Sao_Paulo'

        return date.toLocaleString('pt-BR', options)
      } else {
        options.timeZone = 'America/New_York'

        return date.toLocaleString('en-US', options)
      }
    },
    fileDownload (idFile, fileName) {
      this.isLoading = true
      let idProcess = this.requestId()
      ProcessService.getProcessFile(idProcess, idFile).then(res => {
        const url = global.window.URL.createObjectURL(new Blob([res.data]))
        const link = global.document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        global.document.body.appendChild(link)
        link.click()
        this.isLoading = false
      })
    },
    formatStatus (value, emptyValue) {
      emptyValue = emptyValue || null

      if (!value) {
        return emptyValue
      }

      let statusList = [
        {
          'id': 1,
          'label': global.instanceApp.$i18n.t('form.invoices.status.opened'),
          'color': '#f1ba26'
        },
        {
          'id': 2,
          'label': global.instanceApp.$i18n.t('form.invoices.status.morethanfive'),
          'color': '#f1ba26'
        },
        {
          'id': 3,
          'label': global.instanceApp.$i18n.t('form.invoices.status.morethanone'),
          'color': '#f1ba26'
        },
        {
          'id': 4,
          'label': global.instanceApp.$i18n.t('form.invoices.status.today'),
          'color': '#f7941d'
        },
        {
          'id': 5,
          'label': global.instanceApp.$i18n.t('form.invoices.status.overdue'),
          'color': '#F3614E'
        },
        {
          'id': 6,
          'label': global.instanceApp.$i18n.t('form.invoices.status.paid'),
          'color': '#5ec65f'
        },
        {
          'id': 7,
          'label': global.instanceApp.$i18n.t('form.invoices.status.parcial'),
          'color': '#f1ba26'
        },
        {
          'id': 8,
          'label': this.$i18n.t('form.invoices.status.embarcado'),
          'color': '#f1ba26'
        },
        {
          'id': 9,
          'label': this.$i18n.t('form.invoices.status.aguardando_embarque'),
          'color': '#f1ba26'
        }
      ]

      let status = statusList.find(obj => {
        if (obj.id === value) {
          return obj
        }
      })
      return ['color:' + status.color, status.label]
    },
    getStatus (row) {
      if (row.status === 2) {
        return 6
      } else if (row.status === 4) {
        return 7
      }

      let dueDate = moment(new Date(row.pay_date))
      let today = moment(new Date())

      let duration = moment.duration(dueDate.diff(today)).asHours()
      duration = parseInt(duration)

      if (duration >= (10 * 24)) {
        return 1
      } else if (duration < (10 * 24) && duration >= (5 * 24)) {
        return 2
      } else if (duration < (5 * 24) && duration > 0) {
        return 3
      } else if (duration <= 0 && duration >= -24) {
        return 4
      } else {
        if (row.operacao === 2 && !row.dataDesembarque) {
          return 8
        } else if (row.operacao === 1 && !row.dataEmbarque) {
          return 9
        }
        return 5
      }
    },
    inconsistencyMobile (data) {
      this.$modal.show(
        SendEmail,
        {
          id_responsavel: data
        },
        { draggable: false,
          adaptive: true,
          scrollable: true,
          clickToClose: false,
          width: '90%',
          height: '80%' },
        { 'before-close': (event) => {
        } },
        { 'close': (event) => {
        } }
      )
    },
    inconsistency (data) {
      this.$modal.show(
        SendEmail,
        {
          id_responsavel: data
        },
        { draggable: false,
          adaptive: true,
          scrollable: true,
          clickToClose: false,
          width: '50%',
          height: '80%' },
        { 'before-close': (event) => {
        } },
        { 'close': (event) => {
        } }
      )
    }
  },
  mounted () {
    let _this = this
    _this.displayTimeline()
    _this.getResponsable()
    _this.displayAttachments()
    _this.answerables()
    if (_this.showFollowUps) {
      _this.displayFollowUps()
    }
  },
  created () {
    let uri = window.location.href.split('?')
    if (uri.length === 2) {
      let vars = uri[1].split('&')
      let getVars = []
      let tmp = ''
      vars.forEach(function (v) {
        tmp = v.split('=')
        if (tmp.length === 2) {
          getVars[tmp[0]] = tmp[1]
        }
      })
    }
  },
  updated () {
  },
  watch: {
    $route (to, from) {
      this.$router.go(0)
    },
    process: function () {
      this.filter.internal_code = this.$route.params.process
      this.filters.filProcesso = this.$route.params.process
      this.$refs.internal_code.value = this.$route.params.process
      this.applyFilter()
    },
    refreshFilter: function () {
      if (this.refreshFilter) {
        this.applyFilter()
        this.$store.dispatch('setRefreshFilter', false)
      }
    }
  }
}
</script>

<style scoped>
  @media (max-width: 600px) {
    .table-responsive-size {
      padding: 0;
      margin: 0 10px;
      width: 94.7% !important;
      border: 1px solid #d9d9d9c2;
      border-radius: 15px 15px 0 0;
    }
    .anexos{
      padding-left: 4%;
      color: #c4c4c4;
      font-size: 15pt;
      margin-bottom: -10px;
      padding-top: 10px;
    }
    .hr-left-mobile{
      border-color: #b9b5b5;
      height: 1px;
      width: 90vw;
    }
    .process-files-content-mobile{
      padding-left: 2%;
    }
    .responsible-attachments-mobile{
      padding-top: 5vh;
    }
    .table-responsive-mobile{
      margin-right: 2vw;
      border: #d9d9d9c2 1px solid;
      border-radius: 10px;
      border-radius: 10px 10px 0 0;
    }
    .process-files-mobile{
      list-style-type: none;
      width: 93vw;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -ms-flex-line-pack: center;
      align-content: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0 1% 0 1%;
      background-color: #ffffff;
      color: #000;
      border-radius: 8px;
    }
    .table-responsable{
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: center;
      align-items: flex-start;
      background-color: #ffffff00;
      color: #000;
      min-height: 14vh;
      padding-top: 15px;
      padding-left: 15px;
      max-width: 100%;
      border: 1px solid #d9d9d9c2;
      border-radius: 15px 15px 0 0;
    }
    .show-more-size{
      height: 4vh;
      display: flex;
      justify-content: center;
      align-items: center;
      color: rgb(255, 255, 255);
      border-radius: 0 0 15px 15px;
      background-color: #84754e;
      margin: 0 10px;
      width: 94.7% !important;
    }
    ._horizontal-scroll{
      box-sizing: border-box;
      overflow-x: scroll;
      overflow-y: hidden;
      text-align: left;
      white-space: nowrap;
      padding-bottom: 12px;
    }
    .timeline{
      width: 250vw !important;
    }
    .container-left .table-follow-up ul {
        margin-left: 0 !important;
    }
    .container-full{
      display:flex;
      max-width: 72vw;
    }
    .ori-dest, .container-right {
      display:none;
    }
    .header-options{
      display: flex;
      flex-direction: row !important;
    }
    .header-timeline-mobile{
      display: flex;
      background-color: #464646;
      color: #fff;
      max-width: 100%;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: column;
      -ms-flex-wrap: wrap;
      flex-wrap: nowrap;
      border-radius: 10px;
      padding-left: 10px;
      margin-bottom: 10px;
      margin: 6px;
    }
    .container-full, .container-left{
     max-width: 100% !important;
     margin: 0 !important;
    }
    .button-back{
      display: none !important;
    }
    .arrow-back-left{
      z-index: 999;
      color: #2c2c2c24;
    }
    .arrow-back-right{
      z-index: 999;
      color: #2c2c2c24;
    }
  }
  @media (min-width: 600px) {
    .arrow-back-left{
      z-index: 999;
      color: hsla(0, 0%, 17%, 0.141);
      position: relative;
      left: 0;
    }
    .container-scroll{
      position: relative;
    }
    .arrow-back-right{
      z-index: 999;
      color: hsla(0, 0%, 17%, 0.141);
      position: fixed;
      right: 0;
    }
    .timeline{
      min-width: 1180px !important;
    }
    .container-right{
      min-width: 24.7%;
      max-width: 30%;
      background-color: #363636;
      margin-right: 0;
      padding: 0;
    }
    .container-left{
      margin-left: 20px;
      min-width: 73%;
      max-width: 80%;
    }
    .responsible-attachments-mobile{
      display:none;
    }
    .show-more-size{
      width: 10vw;
      height: 4vh;
      display: flex;
      justify-content: center;
      align-items: center;
      color: rgb(255, 255, 255);
      border-radius: 10px;
      background-color: #84754e;
      margin-top: 15px;
    }
    ._horizontal-scroll::-webkit-scrollbar-track {
      background-color: #f4f4f41a;
    }
    ._horizontal-scroll::-webkit-scrollbar {
        width: 3px;
        background: #F4F4F4;
    }
    ._horizontal-scroll::-webkit-scrollbar-thumb {
        background: #dad7d76c;
    }
    ._horizontal-scroll {
      box-sizing: border-box;
      overflow-x: scroll;
      overflow-y: hidden;
      text-align: left;
      white-space: nowrap;
    }
    .header-timeline-mobile{
      display: none;
    }
  }
  .file-date{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 8px;
  }
  .process-files-item-date{
    font-weight: 100;
    font-size: 10px;
  }
  .table{
    width: 100% !important;
  }
  .followUp{
    width: 6vw;
  }
  .process-files{
    list-style-type: none;
    max-width: 96%;;
    min-width: 10vw;
    margin-right: 6px;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    padding: 0 1% 0 1%;
    background-color:#464646;
    border-radius: 8px;
  }
  .hr-left{
    border-color: #464646;
    height: 1px;
  }
  .container-origin{
    font-size: 20pt;
    font-weight: 100;
    font-family: Arial, Helvetica, sans-serif;
    padding-top: 15pt;
    padding-bottom: 10pt;
    color: #5A4D34;
  }
  .container-ref{
    font-size: 15pt;
    font-weight: 100;
    font-family: Arial, Helvetica, sans-serif;
    padding-top: 12pt;
    padding-bottom: 17pt;
    color: #5A4D34;
  }
  .height-responsable{
    padding-top: 30px;
    width: 100%;
    padding-left: 4%;
    margin-bottom: 5%;
    padding-right: 4%;
    overflow: hidden;
    color: #fff;
  }
  .awaiting{
    background-color: #fff;
    color: #363636;
    border-radius: 0;
    flex-direction: row;
    width: 100%;
  }
  .awaiting-msg{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-evenly;
    margin-right: 22%;
    padding-left: 21px;
  }
  .awaiting-msg h5{
    background: #FFFFFF;
    font-family: 'Pluto Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #3D3D3D;
    padding-top: 10px;
  }
  .circle{
    display:flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    min-width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-left: 15px;
    background-color: #84754E;
  }
  .inner-circle{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: rgb(255, 255, 255);
  }
  .table-responsive-size{
    height: 30vh;
    overflow: hidden;
  }
  .table-responsive, .table-responsive-size{
    padding: 0;
    width: 100%;
    margin-right: 10px;
  }
  .item-process-header-timeline{
    font-family: 'Pluto Sans';
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    font-weight: 100;
  }
  .item-header-timeline{
    font-family: 'Pluto Sans';
    font-style: normal;
    font-weight: 370;
    font-size: 12px;
    line-height: 18px;
    color: #FFFFFF;
    opacity: 0.6;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 0px;
  }
  .show-more-mobile{
    margin-right: 2vw;
    min-width: 10vw;
    height: 4vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(255, 255, 255);
    border-radius: 0 0 15px 15px;
    background-color: #84754e;
  }
  .show-more{
    max-width: 33%;
    min-width: 45%;
    height: 4vh;
    background-color: #84754e;
    margin-bottom: 6%;
    border-radius: 8px;
    display:flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }
  .show-more-responsable{
    min-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    height: 4vh;
    border-radius: 0 0 15px 15px;
    background-color: #84754e;
  }
  .container-left .table-follow-up ul{
    display: flex;
    justify-content: flex-start;
    margin-left: 25px;
  }
  .container-full{
    display: flex;
    flex-direction: column;
  }
  .header-timeline{
    display: flex;
    padding-left: 3%;
    height: 39vh;
    flex-wrap: nowrap;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    color: #fff;
  }
  .header-options{
    display: flex;
    flex-direction: column;
  }
  .container-timeline{
    display:flex;
  }
  .timeline{
    padding: 0;
  }
  .container-full .timeline{
    display: flex;
    justify-content: space-evenly;
  }
  .container-full .timeline-position{
    border-radius: 10px;
    overflow: hidden;
    width: 19%;
    text-overflow: '.'
  }
  .container-full .nav{
    display: flex;
  }
  .page-footer {
    color: #A5A4A4;
    border: 0;
    padding-top: 0;
    padding-bottom: 35px;
  }
  .table-follow-up {
    width: 100%;
  }
  .start{
    background-color: #0F9D57;
    height: 75%;
    min-height: 80px;
  }
  .responsible-attachments{
    width: 100%;
  }
  .height-left, .height-left-mobile{
    height: 18vh;
    overflow: hidden;
    color: #fff;
  }
  .awaiting-boarding, .awaiting{
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    border: 1px #3636363b solid;
    border-radius: 15px;
    flex-direction: column;
    width: 100%;
  }
  .button-back{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 14%;
    margin-top: -3%;
    padding-bottom: 20px;
  }
  .button-back a{
    width: 35px;
    height: 35px;
    background-color: #84754E;
    color: #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }
  .boarding{
    display: flex;
    color: #000;
  }
  .boarding .media{
    margin-left: -5px;
  }
  .container-timeline .align-middle{
    width: 94%;
  }
  #linha-horizontal {
    width: 80%;
    border: 2px #0F9D57 solid;
    margin-top: 16%;
    margin-left: -30px;
  }
  .table-invoices{
    background-color: #363636;
    color: #fff;
  }
  .heightLeft{
    height: 10vh;
  }
  .anexos{
    padding-left: 4%;
    color: #c4c4c4;
    font-size: 15pt;
  }
  .process-files-content{
    padding-left: 4%;
  }
  .timeline-position, .start, .awaiting, .boarding{
    height: 4vw;
    min-height: 65px;
  }
  .boarding .cd-timeline-content h5{
    color:#363636 !important;
  }
  .process{
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 18px;
  }
  .process-file-align{
    display:flex;
    align-items: center;
  }
  .timeline:before {
    display: none;
  }
  .content-wrapper{
    overflow: hidden;
  }
</style>
