<template>
  <div class="process-details-content">
    <div class="container">
      <div class="row full-heigth">
        <loading :active.sync="isLoading"
                 :can-cancel="false"
                 :is-full-page="fullPage"></loading>
        <!-- INICIO Detalhes do processo -->
        <div class="col-md-3 text-left dark-box">

          <div class="row" >
            <div class="row col-12 mb-5">
              <div v-if="process.internal_code" class="row col-12 mb-3">
                <div class="col-12 text-muted">Ref. Royal Cargo:</div>
                <div class="col-12 ">{{ process.internal_code }}</div>
              </div>
              <div v-if="process.customer_reference" class="row col-12 mb-3">
                <div class="col-12 text-muted">Referência</div>
                <div class="col-12 ">{{ process.customer_reference }}</div>
              </div>
              <div v-if="process.hbl" class="row col-12 mb-3">
                <div class="col-12 text-muted">Número BL</div>
                <div class="col-12 ">{{ process.hbl }}</div>
              </div>
            </div>

            <div class="row col-12 mb-5">
              <div v-if="process.customer_name" class="row col-12 mb-3">
                <div class="col-12 text-muted">Cliente</div>
                <div class="col-12 ">{{ process.customer_name }}</div>
              </div>

              <div v-if="process.shipper_name" class="row col-12 mb-3">
                <div class="col-12 text-muted">Exportador</div>
                <div class="col-12 ">{{ process.shipper_name }}</div>
              </div>
              <div v-if="process.consignee_name" class="row col-12 mb-3">
                <div class="col-12 text-muted">Importador</div>
                <div class="col-12 ">{{ process.consignee_name }}</div>
              </div>
              <div v-if="process.notify_name" class="row col-12 mb-3">
                <div class="col-12 text-muted">Notify</div>
                <div class="col-12 ">{{ process.notify_name }}</div>
              </div>

            </div>

            <div class="row col-12 mb-5">
              <div v-if="process.origin_name" class="row col-12 mb-3">
                <div class="col-12 text-muted">Origem</div>
                <div class="col-12 ">{{ process.origin_name }}</div>
              </div>
              <div v-if="process.destination_name" class="row col-12 mb-3">
                <div class="col-12 text-muted">Destino</div>
                <div class="col-12 ">{{ process.destination_name }}</div>
              </div>
              <div v-if="process.delivery_place" class="row col-12 mb-3">
                <div class="col-12 text-muted">Destino Final</div>
                <div class="col-12 ">{{ process.delivery_place }}</div>
              </div>
              <div v-if="process.modality" class="row col-12 mb-3">
                <div class="col-6 text-muted">Modalidade</div>
                <div class="col-6 ">{{ process.modality }}</div>
              </div>
              <div v-if="process.id_incoterm" class="row col-12 mb-3">
                <div class="col-12 text-muted">Incoterm</div>
                <div class="col-12 ">{{ (process.id_incoterm)?process.id_incoterm.description:null }}</div>
              </div>
            </div>

            <div class="row col-12 mb-5">
              <div v-if="process.type_modal && process.type_modal == 2 && process.type_operation && process.type_operation == 1" class="row col-12 mb-3">
                <div class="col-12 text-muted">Booking NR#:</div>
                <div class="col-12 ">{{ process.reservation_number }}</div>
              </div>
              <div v-if="process.qty_container" class="row col-12 mb-3">
                <div class="col-12 text-muted">Total de Containers</div>
                <div class="col-12 ">{{ process.qty_container }}</div>
              </div>
              <div v-if="process.container_type" class="row col-12 mb-3">
                <div class="col-12 text-muted">Tipo de Container</div>
                <div class="col-12 ">{{ process.container_type }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- FIM Detalhes do processo -->
        <!-- Inicio timeline-->
        <div class="col-md-9">
          <div class="close-button mb-4">
            <div class="btn btn-link buttonClose d-inline-block" @click="onClose()">
              <span><i class="text-primary rc-Ativo-36"> </i></span>
            </div>
          </div>
          <ul class="nav line-tabs borderless flex-center mb-5">
            <li class="nav-item">
              <a class="nav-link" v-bind:class="{ active: showTimeline }" v-on:click="displayTimeline()" data-toggle="tab" >Timeline</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" v-bind:class="{ active: showFollowUps }" v-on:click="displayFollowUps()" data-toggle="tab" >Acompanhamentos</a>
            </li>
            <li class="nav-item" >
              <a class="nav-link" v-bind:class="{ active: showInvoices }"  v-on:click="displayInvoices()" data-toggle="tab" >Faturas</a>
            </li>
            <li class="nav-item" >
              <a class="nav-link" v-bind:class="{ active: showAttachments }"  v-on:click="displayAttachments()" data-toggle="tab" >Anexos</a>
            </li>
            <li class="nav-item" >
              <a class="nav-link" v-bind:class="{ active: showAnswerables }"  v-on:click="displayAnswerables()" data-toggle="tab" >Responsáveis</a>
            </li>
          </ul>

          <!-- Answerables -->
          <div v-if="showAnswerables">
              <responsavel-box  v-for="responsavel in processAnswerable" v-bind:key="responsavel.id"  :responsavel="responsavel"/>
          </div>
          <!-- FIM Answerables -->
          <!-- Invoices -->
          <div v-if="showInvoices">
            <div class="table-responsive ">
              <table class="table align-middle">
                <thead>
                <tr>
                  <th>Tipo</th>
                  <th>Número</th>
                  <th>Vencimento</th>
                  <th>Status</th>
                  <th>Valor do título</th>
                  <th></th>
                </tr>
                </thead>
                <invoice-info v-for="invoice in processInvoices" v-bind:key="invoice.id" :invoice="invoice"
                  :begin-opened="beginOpenedInvoice(idInvoice, invoice)"  :user="user"
                  @invoiceGenerated="reloadInvoices()" @reloadAttachments="reloadAttachments"/>
                <tbody v-if="processInvoices.length <= 0">
                  <tr>
                    <td colspan="2">Nenhuma fatura disponível.</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- FIM Invoices -->
          <!-- Timeline -->
          <div v-if="showTimeline">
            <div class="cd-timeline cd-timeline-small cd-timeline-light center-orientation " id="timeline-2">
              <timeline-item v-for="(followUp, index) in processTimeline" :key="index" :item="followUp">
              </timeline-item>
            </div>
          </div>
          <!-- FIM Timeline -->
          <!-- Follow Ups -->
          <div v-if="showFollowUps">
            <div class="table-responsive ">
                <table class="table align-middle">
                <thead>
                  <tr>
                    <th>Data</th>
                    <th>Descrição</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(followUp, index) in processFollowUps" :key="index" :class="{internal: followUp.internal}">
                    <td>{{ formatDateTime(followUp.date) }}</td>
                    <td class="text-left" v-html="followUp.description"></td>
                  </tr>
                  <tr v-if="processFollowUps.length <= 0">
                    <td colspan="2">Nenhum acompanhamento disponível.</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- FIM Timeline -->

          <!--  Files -->
          <div v-if="showAttachments" class="process-files-content">
            <div class="table-responsive ">
            <table class="table align-middle">
              <thead>
                  <tr>
                    <th class="text-left">Descrição do documento</th>
                    <th class="text-left">Grupo</th>
                    <th class="text-left">Data</th>
                    <th>Opções</th>
                  </tr>
              </thead>
              <tbody>
                <tr v-for="file in processFiles" :key="file.id_file">
                  <td class="text-left align-middle"> {{ file.name }}</td>
                  <td class="text-left align-middle"> {{ file.file_group_name }}</td>
                  <td class="text-left align-middle"> {{ $util.formatDate(file.date) }}</td>
                  <td class="text-center align-middle"><button class="btn btn-link no-padding" v-on:click="fileDownload(idProcess, file.id_file, file.name)"> <i class="text-primary ft-download  font-20"></i></button></td>
                </tr>

                <tr v-if="processFiles.length <= 0">
                  <td colspan="3">Nenhum arquivo disponível.</td>
                </tr>
              </tbody>
            </table>
            </div>
          </div>
          <!--  FIM Files -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProcessService from '@/services/Process/ProcessService'
import TimelineItem from '@/components/Timeline/TimelineItem.vue'
import ResponsavelBox from '@/views/Kpi/Process/ResponsavelBox'
import InvoiceInfo from '@/views/Kpi/Process/InvoiceInfo'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  name: 'ProcessDetailsView',
  components: {
    TimelineItem,
    ResponsavelBox,
    InvoiceInfo,
    Loading
  },
  data () {
    return {
      processTimeline: [],
      processFollowUps: [],
      processFiles: [],
      idInvoice: null,
      processAnswerable: [],
      processInvoices: [],
      isLoading: false,
      fullPage: true
    }
  },
  methods: {
    beginOpenedInvoice (idInvoice, invoice) {
      if (idInvoice) {
        if (idInvoice === invoice.id_invoice) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    },
    reloadInvoices () {
      let _this = this
      _this.isLoading = true
      ProcessService.getProcessInvoices(this.idProcess).then(res => {
        _this.processInvoices = res.data.data
      }).finally(() => {
        _this.isLoading = false
      })
    },
    onClose () {
      global.instanceApp.$alertSwal.close()
    },
    displayAnswerables () {
      let _this = this
      if (_this.processAnswerable.length <= 0) {
        _this.isLoading = true
        ProcessService.getProcessAnswerable(this.idProcess).then(res => {
          _this.processAnswerable = res.data.data
        }).finally(() => {
          _this.isLoading = false
        })
      }
      _this.showTimeline = false
      _this.showFollowUps = false
      _this.showAttachments = false
      _this.showAnswerables = true
      _this.showInvoices = false
    },
    clearAttachments () {
      this.processFiles = []
    },
    displayAttachments () {
      let _this = this
      if (_this.processFiles.length <= 0) {
        _this.isLoading = true
        ProcessService.getProcessFiles(this.idProcess).then(res => {
          _this.processFiles = res.data.data
        }).finally(() => {
          _this.isLoading = false
        })
      }
      _this.showTimeline = false
      _this.showFollowUps = false
      _this.showAttachments = true
      _this.showAnswerables = false
      _this.showInvoices = false
    },
    reloadAttachments () {
      this.clearAttachments()
      this.displayAttachments()
    },
    displayFollowUps () {
      let _this = this
      if (_this.processFollowUps.length <= 0) {
        _this.isLoading = true
        ProcessService.getProcessFollowUps(this.idProcess).then(res => {
          _this.processFollowUps = res.data.data
        }).finally(() => {
          _this.isLoading = false
        })
      }

      _this.showTimeline = false
      _this.showFollowUps = true
      _this.showAttachments = false
      _this.showAnswerables = false
      _this.showInvoices = false
    },
    displayTimeline () {
      let _this = this
      if (_this.processTimeline.length <= 0) {
        _this.isLoading = true
        ProcessService.getProcessTimeline(this.idProcess).then(res => {
          _this.processTimeline = res.data.data
        }).finally(() => {
          _this.isLoading = false
        })
      }
      _this.showAttachments = false
      _this.showFollowUps = false
      _this.showTimeline = true
      _this.showAnswerables = false
      _this.showInvoices = false
    },
    displayInvoices () {
      let _this = this
      if (_this.processInvoices.length <= 0) {
        _this.isLoading = true
        ProcessService.getProcessInvoices(this.idProcess).then(res => {
          _this.processInvoices = res.data.data
        }).finally(() => {
          _this.isLoading = false
        })
      }
      _this.showTimeline = false
      _this.showFollowUps = false
      _this.showAttachments = false
      _this.showAnswerables = false
      _this.showInvoices = true
    },
    formatDateTime (value, locale, emptyValue) {
      emptyValue = emptyValue || null

      if (!value) {
        return emptyValue
      }

      locale = locale || 'pt-BR'

      let options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      }

      if (!value) {
        return null
      }

      let date = new Date(value)

      if (locale === 'pt-BR') {
        options.timeZone = 'America/Sao_Paulo'

        return date.toLocaleString('pt-BR', options)
      } else {
        options.timeZone = 'America/New_York'

        return date.toLocaleString('en-US', options)
      }
    },
    fileDownload (idProcess, idFile, fileName) {
      this.isLoading = true
      ProcessService.getProcessFile(idProcess, idFile).then(res => {
        const url = global.window.URL.createObjectURL(new Blob([res.data]))
        const link = global.document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        global.document.body.appendChild(link)
        link.click()
      }).finally(() => {
        this.isLoading = false
      })
    }
  },
  mounted () {
    let _this = this
    if (_this.showTimeline) {
      _this.displayTimeline()
    } else if (_this.showFollowUps) {
      _this.displayFollowUps()
    } else if (_this.showAttachments) {
      _this.displayAttachments()
    } else if (_this.showAnswerables) {
      _this.displayAnswerables()
    } else if (_this.showInvoices) {
      _this.displayInvoices()
    }
  }
}
</script>

<style scoped>

  .close-button {
    position: absolute;
    top: 15px;
    right: 33px;
  }

  tr.internal {
    background-color: #fcf8e3;
  }

</style>
