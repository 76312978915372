<template>
  <tbody>
  <tr v-show="opened">
    <td colspan="6" class="p-3">
      <div class="table-responsive">
        <table class="table align-middle ">
          <thead>
          <tr>
            <th class="text-left">Taxa</th>
            <th>Moeda</th>
            <th>Valor</th>
            <th>Conversão</th>
            <th>Convertido</th>
          </tr>
          </thead>
          <tbody >
          <tr v-for="taxa in invoice.taxas" v-bind:key="taxa.id" >
            <td class="text-left">{{ taxa.taxa }}</td>
            <td>{{ taxa.moeda }}</td>
            <td>{{ $util.formatNumber(taxa.valor_total_original, 2) }}</td>
            <td>{{ $util.formatNumber((taxa.fator != 1.0) ? taxa.fator : null, 6, ',', '.', '-') }}</td>
            <td>{{ $util.formatNumber(taxa.valor_total) }}</td>
          </tr>
          <tr v-if="!invoice.taxas || invoice.taxas.length <= 0">
            <td colspan="2">Nenhuma taxa disponível.</td>
          </tr>
          </tbody>
        </table>
        <div class="row">
          <div class="col-12">
            <span class="pull-right h2 mb-0">{{ $util.formatNumber(invoice.estimated_value, 2)}}</span>
            <div class="pull-right mb-0  pr-4">{{ invoice.currency_initials }} / {{ invoice.currency }}</div>
          </div>
          <div class="col-12 text-right font-11 text-warning mb-2" v-if="emAberto">
            Prévia de valores, valores ainda podem sofrer alterações.
          </div>
          <div class="col-12 text-right font-11 text-warning mb-2" v-else-if="emAbertoInvoiceOnlinePendente">
            Prévia de valores, valores ainda podem sofrer alterações, não autorizado o pagamento.
          </div>
          <div class="col-12 text-right font-11 text-warning mb-2" v-else-if="emAbertoInvoiceOnline">
            Processo com os valores finais atualizados, por gentileza atualizar com a cotação do dia para pagamento.
          </div>

          <div class="col-12 text-right font-11 text-warning mb-4">
            Valor de acordo com a cotação do dia {{ (invoice.status === 2) ? $util.formatDate(invoice.dataPagamento) : $util.formatDate(invoice.data) }}
          </div>

        </div>
        <!--  Ações       -->
        <div class="row">
          <div class="col-12 col-sm-3 mb-3 mt-0" aria-label="Geração de Fatura">
            <button type="button" v-on:click="inconsistency(invoice)" class="btn btn-link pull-left">
              <small>Algo errado?</small>
            </button>
          </div>
          <div class="col-12 col-sm-9 mb-3 mt-0" aria-label="Geração de Fatura">
            <button  v-if="invoice.finalizada_portal || invoice.tipo == 2" type="button" v-on:click="uploadPaymentVoucher(invoice)"
              class="btn btn-primary pull-right ml-3">
              <span class="font-11">Enviar Comprovante</span>
            </button>
            <button v-if="getStatus(invoice) === 6" type="button"
              v-on:click="openReceiptSelector(invoice)"
              class="btn btn-primary pull-right ml-3">
              <span class="font-11">Gerar Recibo</span>
            </button>
            <button v-if="showGenerateAction" type="button" v-on:click="generate(invoice)"
                    class="btn btn-primary pull-right ml-3">
              <span class="font-11">Atualizar e Imprimir</span>
            </button>
          </div>
        </div>
      </div>
    </td>
  </tr>
  </tbody>
</template>

<script>
import moment from 'moment'

import InconsistencyView from '@/views/Invoice/Generate/InconsistencyView.vue'
import UploadPaymentVoucherView from '@/views/Invoice/Generate/UploadPaymentVoucherView.vue'
import GeneratePaySlipView from '@/views/Invoice/Generate/GenerateReceiptView.vue'
import GenerateView from '@/views/Invoice/Generate/GenerateView.vue'
import InvoiceService from '@/services/InvoiceService'

export default {
  name: 'InvoiceInfo',
  props: {
    invoice: {
      type: Object,
      required: true
    },
    placeholder: {
      type: String,
      default: global.instanceApp.$i18n.t('filter.Filter_by')
    },
    noOptions: {
      type: String,
      default: 'Nenhum resultado encontrado'
    },
    beginOpened: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      opened: false
    }
  },
  mounted () {
    this.opened = this.beginOpened
  },
  methods: {
    inconsistency (data) {
      this.$modal.show(
        InconsistencyView,
        {
          invoice: data
        },
        { draggable: false, width: '78%', height: 'auto', adaptive: true },
        { 'before-close': (event) => {
        } },
        { 'close': (event) => {
        } }
      )
    },
    uploadPaymentVoucher (data) {
      InvoiceService.checkGenerationTime(data.id_invoice).then((res) => {
        if (res.data.status === 'ok') {
          this.$modal.show(
            UploadPaymentVoucherView,
            {
              invoice: data
            },
            { draggable: false, width: '78%', height: 'auto', adaptive: true },
            {
              'before-close': (event) => {
              },
              'closed': (event) => {
                this.$emit('reloadAttachments')
              }
            }
          )
        } else {
          if (res.data.status === 'feriado') {
            this.$alertSwal.modalAlert('Aviso', '<div style="width:400px;text-align: left">' + res.data.data + '</div>').then((result) => {
            })
          } else {
            this.$alertSwal.modalAlert('Aviso', 'A emissão de faturas está disponível de Seg à Sex. <br/>Dàs ' + res.data.data.begin + ' às ' + res.data.data.end).then((result) => {
            })
          }
        }
      })
    },
    generate (data) {
      InvoiceService.checkGenerationTime(data.id_invoice).then((res) => {
        if (res.data.status === 'ok') {
          this.$modal.show(
            GenerateView,
            {
              invoice: data
            },
            { draggable: false, width: '78%', height: 'auto', adaptive: true },
            { 'before-close': (event) => {
            } },
            { 'close': (event) => {
            } }
          )
        } else {
          if (res.data.status === 'feriado') {
            this.$alertSwal.modalAlert('Aviso', '<div style="width:400px;text-align: left">' + res.data.data + '</div>').then((result) => {
            })
          } else {
            this.$alertSwal.modalAlert('Aviso', 'A emissão de faturas está disponível de Seg à Sex. <br/>Dàs ' + res.data.data.begin + ' às ' + res.data.data.end).then((result) => {
            })
          }
        }
      })
    },
    userAllowOnlineInvoice () {
      let invoice = this.invoice

      if (!this.user) {
        return false
      } else if (this.user.roles.indexOf('ROLE_EMPLOYEE') !== -1) {
        return true
      } else if (this.user.customers_allow_online_invoice_list.length <= 0) {
        return false
      } else if (this.user.customers_allow_online_invoice_list.includes(invoice.idpessoa)) {
        return true
      }

      return false
    },
    getStatus (row) {
      if (row.status === 2) {
        // Pago
        return 6
      } else if (row.status === 4) {
        // Parcial
        return 7
      }

      let dueDate = moment(new Date(row.pay_date))
      let today = moment(new Date())

      let duration = moment.duration(dueDate.diff(today)).asHours()
      duration = parseInt(duration)

      if (duration >= (10 * 24)) {
        return 1
      } else if (duration < (10 * 24) && duration >= (5 * 24)) {
        return 2
      } else if (duration < (5 * 24) && duration > 0) {
        return 3
      } else if (duration <= 0 && duration >= -24) {
        return 4
      } else {
        return 5
      }
    },
    formatStatus (value, emptyValue) {
      emptyValue = emptyValue || null

      if (!value) {
        return emptyValue
      }

      let statusList = [
        {
          'id': 1,
          'label': global.instanceApp.$i18n.t('form.invoices.status.opened'),
          'color': '#f1ba26'
        },
        {
          'id': 2,
          'label': global.instanceApp.$i18n.t('form.invoices.status.morethanfive'),
          'color': '#f1ba26'
        },
        {
          'id': 3,
          'label': global.instanceApp.$i18n.t('form.invoices.status.morethanone'),
          'color': '#f1ba26'
        },
        {
          'id': 4,
          'label': global.instanceApp.$i18n.t('form.invoices.status.today'),
          'color': '#f7941d'
        },
        {
          'id': 5,
          'label': global.instanceApp.$i18n.t('form.invoices.status.overdue'),
          'color': '#F3614E'
        },
        {
          'id': 6,
          'label': global.instanceApp.$i18n.t('form.invoices.status.paid'),
          'color': '#5ec65f'
        },
        {
          'id': 7,
          'label': global.instanceApp.$i18n.t('form.invoices.status.parcial'),
          'color': '#f1ba26'
        }
      ]

      let status = statusList.find(obj => {
        if (obj.id === value) {
          return obj
        }
      })

      // return '<span style="color: ' + status.color + '">' + status.label + '</span>'
      return '<span style="color: ' + status.color + '">' + status.label + '</span>'
    },
    openReceiptSelector (data) {
      this.$modal.show(
        GeneratePaySlipView,
        {
          invoice: data
        },
        { draggable: false, width: '78%', height: 'auto', adaptive: true },
        {
          'before-close': (event) => {
          },
          'closed': (event) => {
          }
        }
      )
    },
    fileDownload (row) {
      // if (row.status !== 6) {
      //   return false
      // }
      InvoiceService.getPaySlipFile(row)
      return false
    }
  },
  computed: {
    emAberto () {
      /** Usuário sem permissão para geração de notas
       *  Fatura
      */
      if (this.invoice.tipo === 1 && !this.userAllowOnlineInvoiceCheck) {
        return true
      }

      return false
    },
    emAbertoInvoiceOnlinePendente () {
      /** Usuário com permissão para geração de notas
       *  Não pago
       *  Fora da data de geração da fatura.
       */
      if (this.userAllowOnlineInvoiceCheck && !this.showGenerateAction) {
        return true
      }

      return false
    },
    emAbertoInvoiceOnline () {
      /** Usuário com permissão para geração de notas
       *  Não pago
       *  Na data de geração da fatura.
       */
      if (this.userAllowOnlineInvoiceCheck && this.showGenerateAction) {
        return true
      }

      return false
    },
    userAllowOnlineInvoiceCheck () {
      // usuário possui permissão para cadear faturas para esse cliente
      if (this.userAllowOnlineInvoice()) {
        return true
      }

      return false
    },
    showGenerateAction () {
      // usuário não possui permissão para cadear faturas para esse cliente
      if (!this.userAllowOnlineInvoiceCheck) {
        return false
      }

      /*
        Para cadear,  na importação quando desembarcar
        Exportação: embarque
       */
      // IM
      if (this.invoice.modalidade === 2 && this.invoice.operacao === 2) {
        return (this.invoice.dataDesembarque && this.getStatus(this.invoice) !== 6)
      } else {
        return (this.invoice.dataEmbarque && this.getStatus(this.invoice) !== 6)
      }
    },
    user () {
      return this.$store.getters.userInfo || null
    }
  },
  watch: {
    user: function () {
      // CODIGO PARA REMOÇÂO DOS MENUS
    }
  }
}
</script>

<style scoped>
  .vertical-center {
      display: flex;
      align-items: center;
  }

  .text-bottom {
    height: 50px;
    display:table-cell;
    vertical-align:bottom;

  }

  .btn {
    margin-bottom: 8px;
  }
  @media (max-width: 1140px) {
    .btn {
      min-width: 155px;

    }
  }
</style>
