<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <div class="row justify-content-md-center">
      <!-- Sidebar -->
      <div class="col-12">
        <filter-side-bar>
          <template slot="ContentSideBar">
            <form id="processes-list-filter" ref='processesListFilter' v-on:submit.prevent="applyFilter()">
              <h5 class="mb-0">Filtrar por:</h5>
              <div>
                <div>
                  <multiple-select :options="customerList" :id-field="'id_customer'" :placeholder="this.$i18n.t('form.customer.label')" :fetchOptions="customerFetchOptions" :value="filters.filCliente" />
                </div>
              </div>

              <div>
                <div class="md-form mb-0">
                  <input id="internal_code" name="internal_code" ref="internal_code"
                         :title="this.$i18n.t('form.process.internal_code')" class="md-form-control" type="text"
                         :value="filters.filProcesso">
                  <label :class="{'active': filters.filProcesso}">{{ $t('form.process.internal_code') }}</label>
                </div>
              </div>

              <div>
                <div class="md-form mb-0">
                  <input id="customer_reference" name="customer_reference" ref="customer_reference"
                         :title="this.$i18n.t('form.process.customer_reference')" class="md-form-control" type="text"
                         :value="filters.filReferenciaCliente">
                  <label :class="{'active': filters.filReferenciaCliente}">{{ $t('form.process.customer_reference') }}</label>
                </div>
              </div>

              <div>
                <div class="md-form mb-0">
                  <input id="hbl" name="hbl" ref="hbl" :title="this.$i18n.t('form.process.bl_house')"
                         class="md-form-control" type="text" :value="filters.filHBL">
                  <label :class="{'active': filters.filHBL}">{{ $t('form.process.bl_house') }}</label>
                </div>
              </div>

              <h6 class="mb-0 mt-4">{{ $t('form.process.period_time_arrival') }}</h6>
              <div class="row">
                <div class="py-1 col-md-6">
                  <div class="md-form mb-0">
                    <div class="input-group-icon input-group-icon-right mb-0">
                      <div class="md-form mb-0">
                        <span class="input-icon input-icon-right">
                          <i class="ti-calendar"></i>
                        </span>
                        <input data-index="2" :title="this.$i18n.t('form.process.period_time_arrival')"
                               id="arrival_begin_date" ref="arrival_begin_date" readonly
                               class="form-date md-form-control" type="text"
                               :value="$util.formatDate(filters.filPeriodoEmbarqueDtInicio, undefined,null)">
                        <label :class="{'active': filters.filPeriodoEmbarqueDtInicio}">{{ $t('filter.Start') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="py-1 col-md-6">
                  <div class="md-form mb-0">
                    <div class="input-group-icon input-group-icon-right mb-4">
                      <div class="md-form mb-4">
                        <span class="input-icon input-icon-right">
                          <i class="ti-calendar"></i>
                        </span>
                        <input data-index="2" :title="this.$i18n.t('form.process.period_time_arrival')"
                               id="arrival_end_date" ref="arrival_end_date" readonly class="form-date md-form-control"
                               type="text" :value="$util.formatDate(filters.filPeriodoEmbarqueDtFim, undefined, null)">
                        <label :class="{'active': filters.filPeriodoEmbarqueDtFim}">{{ $t('filter.End') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <h6 class="mb-0 mt-0">{{ $t('form.process.period_time_departure') }}</h6>
              <div class="row">
                <div class="py-1 col-md-6">
                  <div class="md-form mb-0">
                    <div class="input-group-icon input-group-icon-right mb-4">
                      <div class="md-form mb-4">
                        <span class="input-icon input-icon-right">
                          <i class="ti-calendar"></i>
                        </span>
                        <input data-index="2" :title="this.$i18n.t('form.process.period_time_departure')"
                               id="departure_begin_date" ref="departure_begin_date" readonly
                               class="form-date md-form-control" type="text"
                               :value="$util.formatDate(filters.filPeriodoDesembarqueDtInicio, undefined, null)">
                        <label :class="{'active': filters.filPeriodoDesembarqueDtInicio}">{{ $t('filter.Start') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="py-1 col-md-6">
                  <div class="md-form mb-0">
                    <div class="input-group-icon input-group-icon-right mb-4">
                      <div class="md-form mb-4">
                        <span class="input-icon input-icon-right">
                          <i class="ti-calendar"></i>
                        </span>
                        <input data-index="2" :title="this.$i18n.t('form.process.period_time_departure')"
                               id="departure_end_date" ref="departure_end_date" readonly
                               class="form-date md-form-control" type="text"
                               :value="$util.formatDate(filters.filPeriodoDesembarqueDtFim, undefined, null)">
                        <label :class="{'active': filters.filPeriodoDesembarqueDtFim}">{{ $t('filter.End') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Operation / Modal -->
              <h6 class="mb-3 mt-4">{{ $t('operation type and modal') }}</h6>
              <div class="row">
                <!-- Filter Operation  -->
                <div class="col-md-6">
                  <div class="md-form mb-0 row">
                    <div class="col-md-9">Importação</div>
                    <div class="col-md-3 text-right no-padding">
                      <label class="ui-switch no-margin small switch-solid switch-warning" :class="{disableLabel: disable.impo}">
                        <input id="operation_impo" value="2" :disabled="disable.impo" v-on:click="validaFiltro" type="checkbox" ref="operation_impo" :checked="filters.filTipoOperacaoModalImportacao"><span></span></label>
                    </div>
                  </div>
                  <div class="md-form mb-0 row">
                    <div class="col-md-9">Exportação</div>
                    <div class="col-md-3 text-right no-padding">
                      <label class="ui-switch no-margin small switch-solid switch-warning" :class="{disableLabel: disable.expo}">
                        <input id="operation_expo" value="1" :disabled="disable.expo" v-on:click="validaFiltro" type="checkbox" ref="operation_expo" :checked="filters.filTipoOperacaoModalExportacao"><span></span>
                      </label>
                    </div>
                  </div>
                  <div class="md-form mb-0 row">
                    <div class="col-md-9">Nacional</div>
                    <div class="col-md-3 text-right no-padding">
                      <label class="ui-switch no-margin small switch-solid switch-warning" :class="{disableLabel: disable.nac}">
                        <input id="operation_nac" value="3" :disabled="disable.nac" v-on:click="validaFiltro" type="checkbox"  ref="operation_nac" :checked="filters.filTipoOperacaoModalNacional"><span></span>
                      </label>
                    </div>
                  </div>
                </div>
                <!-- Filter Modal  -->
                <div class="col-md-6">
                  <div class="md-form mb-0 row">
                    <div class="col-md-9">Marítimo</div>
                    <div class="col-md-3 text-right no-padding">
                      <label class="ui-switch no-margin small switch-solid switch-warning" :class="{disableLabel: disable.mar}">
                        <input id="modal_mar" value="2" :disabled="disable.mar" v-on:click="validaFiltro" type="checkbox" ref="modal_mar" :checked="filters.filTipoOperacaoModalMaritimo"><span></span></label></div>
                  </div>
                  <div class="md-form mb-0 row">
                    <div class="col-md-9">Aéreo</div>
                    <div class="col-md-3 text-right no-padding">
                      <label class="ui-switch no-margin small switch-solid switch-warning" :class="{disableLabel: disable.air}">
                        <input id="modal_air" value="1" :disabled="disable.air" v-on:click="validaFiltro" type="checkbox" ref="modal_air" :checked="filters.filTipoOperacaoModalAereo"><span></span>
                      </label>
                    </div>
                  </div>
                  <div class="md-form mb-0 row">
                    <div class="col-md-9">Terrestre</div>
                    <div class="col-md-3 text-right no-padding">
                      <label class="ui-switch no-margin small switch-solid switch-warning" :class="{disableLabel: disable.land}">
                        <input id="modal_land" value="3" :disabled="disable.land" v-on:click="validaFiltro" type="checkbox"  ref="modal_land" :checked="filters.filTipoOperacaoModalTerrestre"><span></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="py-1 text-center">
                <div class="md-form mb-0">
                  <div class="row">
                    <div class="col-6">
                      <button type="submit" class="btn btn-primary btn-fill btn-block mr-2 ">{{ $t('filter.apply') }}
                      </button>
                    </div>
                    <div class="col-6">
                      <button type="button" v-on:click="resetFilter" class="btn btn-cancel btn-fill btn-block mr-2 ">{{
                        $t('filter.reset') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </template>
        </filter-side-bar>
      </div>

      <!-- Navbar -->
      <div class="col-12 mb-3">
        <div class="card no-bottom-margin">
          <div class="section-sub-menu">
            <div class="section-sub-menu">
              <ul class="nav line-tabs borderless flex-center">
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === null }"
                     v-on:click="applyStatusFilter(null)" data-toggle="tab">Todos</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 1 }"
                     v-on:click="applyStatusFilter(1)" data-toggle="tab">{{ $t('form.process.status.open') }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 2 }"
                     v-on:click="applyStatusFilter(2)" data-toggle="tab">{{ $t('form.process.status.awaiting_shipping')
                    }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 3 }"
                     v-on:click="applyStatusFilter(3)" data-toggle="tab">{{ $t('form.process.status.boarded') }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 4 }"
                     v-on:click="applyStatusFilter(4)" data-toggle="tab">{{ $t('form.process.status.landed') }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 5 }"
                     v-on:click="applyStatusFilter(5)" data-toggle="tab">{{ $t('form.process.status.paided') }}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: filter.id_status === 6 }"
                     v-on:click="applyStatusFilter(6)" data-toggle="tab">{{ $t('form.process.status.finalized') }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- Bloco com os Filtros -->
      <div class="col-12 mb-3">
        <filter-container :activeFilterList="activeFilterList" />
      </div>

      <div class="col-12 col-sm-12">
        <div class="row">
          <div :class="processTableSize">
            <div class="card">
              <div class="card-body">
                <!-- Table -->
                <!--<div v-on:click="toggleColumn">Config</div>-->
                <div class="table-responsive ">
                  <vuetable ref="vuetable"
                            :noDataTemplate="this.$i18n.t('noting to result')"
                            :api-url="processEndpoint"
                            :fields="fieldsTable"
                            :sort-order="sortOrder"
                            :css="css.table"
                            pagination-path="pagination"
                            :per-page="20"
                            :multi-sort="multiSort"
                            :append-params="filter"
                            multi-sort-key="shift"
                            :http-options="httpOptions"
                            track-by="id_process"
                            @vuetable:pagination-data="onPaginationData"
                            @vuetable:loading="onLoading"
                            @vuetable:loaded="onLoaded"
                            @vuetable:load-error="handleLoadError">

                    <template slot="ETA_ATA" slot-scope="props">
                      {{ $util.formatDate((props.rowData.arrival_date ? props.rowData.arrival_date : props.rowData.estimated_arrival_date), '-') }}
                    </template>
                    <template slot="ETD_ATD" slot-scope="props">
                      {{ $util.formatDate((props.rowData.departure_date ? props.rowData.departure_date : props.rowData.estimated_departure_date), '-') }}
                    </template>
                    <template slot="actions" slot-scope="props">
                      <div class="custom-actions">
                        <router-link :to="{ path: `/processdetail?${props.rowData.id_process}`}">
                          <i class="text-primary  rc-Ativo-210 font-20"></i>
                        </router-link>
<!--                        <button class="btn btn-link"-->
<!--                                @click="getProcessFiles(props.rowData, props.rowIndex)">-->
<!--                          <i class="text-primary rc-Ativo-1  font-20"></i>-->
<!--                        </button>-->
                      </div>
                    </template>
                  </vuetable>

                </div>
                <vuetable-pagination ref="pagination"
                                     :css="css.pagination"
                                     @vuetable-pagination:change-page="onChangePage"
                ></vuetable-pagination>
                <!-- END Table -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Vuetable, { VuetablePagination } from 'vuetable-2'
import FilterSideBar from '@/components/FilterSideBar.vue'
import ProcessDetailsView from '@/components/ProcessDetailsView.vue'
import ProcessDetails from './ProcessDetails.vue'
import FilterContainer from '@/components/FilterContainer'
import dayjs from 'dayjs'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// Components

// MultipleSelect
import MultipleSelect from '@/components/Forms/MultipleSelect.vue'

// Services
import CustomerService from '@/services/CustomerService'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

export default {
  name: 'ProcessList',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('kpi.section.process') + ' - %s'
    }
  },
  data () {
    return {
      mounting: false,
      customerList: [],
      isLoading: false,
      fullPage: true,
      selectedProcess: null,
      processTableSize: 'col-12',
      processDetail: false,
      processEndpoint: process.env.VUE_APP_APIENDPOINT + 'v2/process',
      multiSort: true,
      activeFilterList: {
        internal_code: this.$route.params.process || null,
        customer_reference: null,
        hbl: null,
        id_status: null,
        customer: [],
        arrival_begin_date: null,
        arrival_end_date: null,
        departure_begin_date: null,
        departure_end_date: null,
        modal: true,
        operation: true
      },
      filter: {
      },
      sortOrder: [
        { field: 'id_status', direction: 'asc' }
      ],
      httpOptions: {
        headers: {
          'Authorization': 'Bearer ' + global.instanceApp.$Cookie.get('_userAuth_')
        }
      },
      css: {
        table: {
          tableClass: 'table table-hover align-middle table-clickable',
          rowCss: 'teste',
          loadingClass: 'loading',
          ascendingIcon: 'ti-angle-double-up',
          descendingIcon: 'ti-angle-double-down',
          handleIcon: 'glyphicon glyphicon-menu-hamburger'
        },
        pagination: {
          infoClass: 'pull-left',
          wrapperClass: 'vuetable-pagination text-center',
          activeClass: 'page-item active',
          disabledClass: 'disabled',
          pageClass: 'btn btn-border',
          linkClass: 'btn btn-border',
          icons: {
            first: '',
            prev: '',
            next: '',
            last: ''
          }
        }
      },
      disable: {
        expo: false,
        impo: false,
        nac: false,
        mar: false,
        air: false,
        land: false
      }
    }
  },
  components: {
    MultipleSelect,
    FilterSideBar,
    Vuetable,
    VuetablePagination,
    Loading,
    FilterContainer
  },
  beforeRouteUpdate (to, from, next) {
    if (to && to.name === 'ProcessList' && to.params.process) {
      this.mounting = true
    }

    next()
  },
  beforeMount () {
    if (this.$route.params.process) {
      this.setDefaultFilters()
      this.mounting = true
      this.filters.filProcesso = this.$route.params.process
    }

    this.buildFilters()
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', true)
    this.$store.dispatch('setShowSearch', false)

    this.$jQuery('#arrival_begin_date, #arrival_end_date, #departure_begin_date, #departure_end_date').datepicker({
      language: 'pt-BR',
      calendarWeeks: true,
      autoclose: true,
      templates: {
        leftArrow: '<i class="ti-angle-left"></i>',
        rightArrow: '<i class="ti-angle-right"></i>'
      }
    })
  },
  methods: {
    buildFilters () {
      if (this.mounting && this.$route.params.process) {
        this.filter.internal_code = this.$route.params.process
      } else if (this.filters.filProcesso) {
        this.filter.internal_code = this.filters.filProcesso
      } else {
        this.filter.internal_code = null
      }

      // Operation Filter
      this.filter.operation = []
      if (this.filters.filTipoOperacaoModalExportacao) {
        this.filter.operation.push(this.filters.filTipoOperacaoModalExportacao)
      }
      if (this.filters.filTipoOperacaoModalImportacao) {
        this.filter.operation.push(this.filters.filTipoOperacaoModalImportacao)
      }
      if (this.filters.filTipoOperacaoModalNacional) {
        this.filter.operation.push(this.filters.filTipoOperacaoModalNacional)
      }

      // Modal Filter
      this.filter.modal = []
      if (this.filters.filTipoOperacaoModalTerrestre) {
        this.filter.modal.push(this.filters.filTipoOperacaoModalTerrestre)
      }
      if (this.filters.filTipoOperacaoModalMaritimo) {
        this.filter.modal.push(this.filters.filTipoOperacaoModalMaritimo)
      }
      if (this.filters.filTipoOperacaoModalAereo) {
        this.filter.modal.push(this.filters.filTipoOperacaoModalAereo)
      }

      this.filter.customer_reference = this.filters.filReferenciaCliente
      this.filter.hbl = this.filters.filHBL
      this.filter.id_status = null
      this.filter.arrival_begin_date = this.filters.filPeriodoDesembarqueDtInicio
      this.filter.arrival_end_date = this.filters.filPeriodoDesembarqueDtFim
      this.filter.departure_begin_date = this.filters.filPeriodoEmbarqueDtInicio
      this.filter.departure_end_date = this.filters.filPeriodoEmbarqueDtFim

      this.filter.customer = (this.filters.filCliente) ? this.filters.filCliente.map(x => (x.id)) : []
    },
    handleLoadError (response) {
    },
    applyStatusFilter (idStatus) {
      event.preventDefault()
      this.filter.id_status = idStatus
      this.$refs.vuetable.refresh()
    },
    // Loader
    applyFilter () {
      this.customerList = []
      let dayParse = 'DD/MM/YYYY'

      // Operation Filter
      this.filters.filTipoOperacaoModalExportacao = this.$refs.operation_expo.checked ? parseInt(this.$refs.operation_expo.value) : null
      this.filters.filTipoOperacaoModalImportacao = this.$refs.operation_impo.checked ? parseInt(this.$refs.operation_impo.value) : null
      this.filters.filTipoOperacaoModalNacional = this.$refs.operation_nac.checked ? parseInt(this.$refs.operation_nac.value) : null

      // Modal Filter
      this.filters.filTipoOperacaoModalTerrestre = this.$refs.modal_land.checked ? parseInt(this.$refs.modal_land.value) : null
      this.filters.filTipoOperacaoModalMaritimo = this.$refs.modal_mar.checked ? parseInt(this.$refs.modal_mar.value) : null
      this.filters.filTipoOperacaoModalAereo = this.$refs.modal_air.checked ? parseInt(this.$refs.modal_air.value) : null
      this.filters.filProcesso = this.$refs.internal_code.value
      this.filters.filReferenciaCliente = this.$refs.customer_reference.value
      this.filters.filHBL = this.$refs.hbl.value
      // this.filters.id_status = null
      this.filters.filPeriodoEmbarqueDtInicio = dayjs(this.$refs.arrival_begin_date.value, dayParse).isValid() ? dayjs(this.$refs.arrival_begin_date.value, dayParse).toISOString() : null
      this.filters.filPeriodoEmbarqueDtFim = dayjs(this.$refs.arrival_end_date.value, dayParse).isValid() ? dayjs(this.$refs.arrival_end_date.value, dayParse).toISOString() : null
      this.filters.filPeriodoDesembarqueDtInicio = dayjs(this.$refs.departure_begin_date.value, dayParse).isValid() ? dayjs(this.$refs.departure_begin_date.value, dayParse).toISOString() : null
      this.filters.filPeriodoDesembarqueDtFim = dayjs(this.$refs.departure_end_date.value, dayParse).isValid() ? dayjs(this.$refs.departure_end_date.value, dayParse).toISOString() : null

      this.buildFilters()

      this.$store.dispatch('setAppliedFilter', this.filters)

      this.hideQuichSidebar()
      this.$refs.vuetable.refresh()
    },
    setDefaultFilters () {
      this.filters.filProcesso = null
      this.filters.filReferenciaCliente = null
      this.filters.filHBL = null
      // this.filter.id_status = null
      this.filters.filPeriodoEmbarqueDtInicio = null
      this.filters.filPeriodoEmbarqueDtFim = null
      this.filters.filPeriodoDesembarqueDtInicio = null
      this.filters.filPeriodoDesembarqueDtFim = null
      this.filters.filCliente = []

      this.filters.filTipoOperacaoModalAereo = 1
      this.filters.filTipoOperacaoModalMaritimo = 2
      this.filters.filTipoOperacaoModalTerrestre = 3

      this.filters.filTipoOperacaoModalExportacao = 1
      this.filters.filTipoOperacaoModalImportacao = 2
      this.filters.filTipoOperacaoModalNacional = 3

      this.$store.dispatch('setAppliedFilter', this.filters)
    },
    resetFilter (event) {
      event.preventDefault()
      this.hideQuichSidebar()
      // TODO: Ezequiel

      this.setDefaultFilters()

      this.buildFilters()

      this.$refs.vuetable.refresh()
    },
    validaFiltro () {
      let contOp = 0
      let contMo = 0

      contOp = this.$refs.operation_expo.checked ? (contOp + 1) : contOp
      contOp = this.$refs.operation_impo.checked ? (contOp + 1) : contOp
      contOp = this.$refs.operation_nac.checked ? (contOp + 1) : contOp
      contMo = this.$refs.modal_mar.checked ? (contMo + 1) : contMo
      contMo = this.$refs.modal_air.checked ? (contMo + 1) : contMo
      contMo = this.$refs.modal_land.checked ? (contMo + 1) : contMo

      this.disable.expo = false
      this.disable.impo = false
      this.disable.nac = false
      this.disable.mar = false
      this.disable.air = false
      this.disable.land = false

      if (contOp === 1) {
        switch (true) {
          case this.$refs.operation_expo.checked:
            this.disable.expo = true
            break
          case this.$refs.operation_impo.checked:
            this.disable.impo = true
            break
          case this.$refs.operation_nac.checked:
            this.disable.nac = true
            break
        }
      }

      if (contMo === 1) {
        switch (true) {
          case this.$refs.modal_mar.checked:
            this.disable.mar = true
            break
          case this.$refs.modal_air.checked:
            this.disable.air = true
            break
          case this.$refs.modal_land.checked:
            this.disable.land = true
            break
        }
      }
    },
    hideQuichSidebar () {
      const sideBar = document.querySelector('#quick-sidebar')
      document.body.classList.remove('has-backdrop')
      sideBar.classList.remove('shined')
    },
    formatStatus (value) {
      if (!value) {
        return ' - '
      }

      let statusList = [
        {
          'id': 1,
          'label': this.$i18n.t('form.process.status.open'),
          'color': '#ababab'
        },
        {
          'id': 2,
          'label': this.$i18n.t('form.process.status.awaiting_shipping'),
          'color': '#f1ba26'
        },
        {
          'id': 3,
          'label': this.$i18n.t('form.process.status.boarded'),
          'color': '#c59b6b'
        },
        {
          'id': 4,
          'label': this.$i18n.t('form.process.status.landed'),
          'color': '#723886'
        },
        {
          'id': 5,
          'label': this.$i18n.t('form.process.status.paided'),
          'color': '#0074a0'
        },
        {
          'id': 6,
          'label': this.$i18n.t('form.process.status.finalized'),
          'color': '#5ec65f'
        }
      ]

      let status = statusList.find(obj => {
        if (obj.id === value) {
          return obj
        }
      })

      return '<span style="color: ' + status.color + '">' + status.label + '</span>'
    },
    onPaginationData (paginationData) {
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    onLoading () {
      this.hideProcessDetail()
      this.isLoading = true
    },
    onLoaded () {
      this.isLoading = false
      this.$jQuery('table.table').reflowTable()
      if (this.mounting && this.$route.params.process) {
        this.mounting = false
        if (this.$refs.vuetable.$data.tableData) {
          if (this.$refs.vuetable.$data.tableData.length > 0) {
            this.showProcessDetails(this.$refs.vuetable.$data.tableData[0], 0, false, true)
          }
        }
      }
    },
    onRowClicked (row) {
      this.showProcessDetails(row.data, row.index, row.attachments)
    },
    hideProcessDetail () {
      this.$jQuery('tr.active').removeClass('active')
      this.processDetail = false
      this.processTableSize = 'col-12'
    },
    /**
     * Display modal with Process details and files if process has files (active tab "Timeline")
     *
     * @param data Process data from line selected
     */
    showProcessDetails (data, rowIndex, attachments, followUps) {
      let me = this
      let showAttachments = attachments || false
      let showFollowUps = followUps || false
      me.processData = data
      if (this.$store.getters.userInfo) {
        var ComponentClass = Vue.extend(ProcessDetailsView, ProcessDetails)
        var processDetailsInstance = new ComponentClass({
          data: {
            user: me.user,
            idProcess: me.processData.id,
            idInvoice: null,
            process: me.processData,
            showTimeline: !showAttachments && !showFollowUps,
            showAttachments: showAttachments,
            showFollowUps: showFollowUps,
            showAnswerables: false,
            showInvoices: false
          }
        })
        processDetailsInstance.$mount()
        let detailsProcessContent = processDetailsInstance.$el
        global.instanceApp.$alertSwal.modalAlert(null, detailsProcessContent, null, 'no-padding', { showConfirmButton: false }).then((result) => { })
      }
    },
    /**
     * Display modal with Process details files (active tab "Anexos")
     *
     * @param data Process data from line selected
     */
    getProcessFiles (data) {
      let me = this

      me.processData = data
      if (this.$store.getters.userInfo) {
        var ComponentClass = Vue.extend(ProcessDetailsView, ProcessDetails)
        var processDetailsInstance = new ComponentClass({
          data: {
            user: me.user,
            idProcess: me.processData.id,
            process: me.processData,
            showTimeline: false,
            showAttachments: true,
            showFollowUps: false,
            showAnswerables: false,
            showInvoices: false
          }
        })
        processDetailsInstance.$mount()
        let detailsProcessContent = processDetailsInstance.$el
        global.instanceApp.$alertSwal.modalAlert(null, detailsProcessContent, null, 'no-padding').then((result) => { })
      }
    },
    /**
     * Triggered when the customer search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    customerFetchOptions (search) {
      let _this = this

      return new Promise((resolve, reject) => {
        let filters = {
          'searchTerm': search
        }

        CustomerService.getCustomers(filters).then(res => {
          _this.customerList = res.data.data
          resolve(_this.customerList)
        }).catch(err => {
          reject(err)
        })
      })
    }
  },
  computed: {
    fieldsTable () {
      return [
        {
          name: 'customer_name',
          title: this.$i18n.t('form.process.customer_name'),
          sortField: 'customer_name',
          visible: ((this.user) ? this.user.multiple_customer : false),
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'internal_code',
          title: this.$i18n.t('form.process.internal_code'),
          sortField: 'internal_code',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'customer_reference',
          title: this.$i18n.t('form.process.customer_reference'),
          sortField: 'customer_reference',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'hbl',
          title: this.$i18n.t('form.process.bl_house'),
          sortField: 'hbl',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'departure_date',
          title: this.$i18n.t('form.process.actual_time_departure'),
          sortField: 'departure_date',
          dataClass: 'text-center wrap-actions',
          titleClass: 'text-center',
          formatter: (value) => this.$util.formatDate(value)
        },
        {
          name: 'arrival_date',
          title: this.$i18n.t('form.process.actual_time_arrival'),
          sortField: 'arrival_date',
          dataClass: 'text-center wrap-actions',
          titleClass: 'text-center',
          formatter: (value) => this.$util.formatDate(value)
        },
        {
          name: 'qty_container',
          title: this.$i18n.t('form.process.total_caontainers'),
          sortField: 'qty_container',
          formatter: (value) => this.$util.formatEmpty(value, undefined, ' - ')
        },
        {
          name: 'id_status',
          title: this.$i18n.t('form.process.status.title'),
          sortField: 'id_status',
          dataClass: 'text-center',
          titleClass: 'text-center',
          formatter: (value) => this.formatStatus(value)
        },
        {
          name: 'actions',
          title: 'Ações',
          dataClass: 'text-center wrap-actions',
          titleClass: 'text-center'
        }
      ]
    },
    user () {
      return this.$store.getters.userInfo || null
    },
    process () {
      return this.$route.params.process || null
    },
    filters () {
      if (this.$Cookie.get('_userStoredFilters_')) {
        return JSON.parse(this.$Cookie.get('_userStoredFilters_'))
      } else {
        return null
      }
    },
    refreshFilter () {
      return this.$store.getters.getRefreshFilter || null
    }
  },
  watch: {
    user: function () {
      // CODIGO PARA REMOÇÂO DOS MENUS
    },
    process: function () {
      this.filter.internal_code = this.$route.params.process
      this.filters.filProcesso = this.$route.params.process
      this.$refs.internal_code.value = this.$route.params.process

      this.applyFilter()
    },
    refreshFilter: function () {
      if (this.refreshFilter) {
        this.applyFilter()
        this.$store.dispatch('setRefreshFilter', false)
      }
    }
  }
}
</script>

<style>
  .swal2-popup {
    width: inherit !important;
  }
  .disableLabel span{
    background-color: rgba(132,117,78,0.6) !important;
    border-color: rgba(132,117,78,0.1) !important;
  }
</style>
